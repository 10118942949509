import { SetStateAction, useState, useEffect } from 'react';
import { DataGrid } from '../dataGrid/DataGrid';
import { CustomHeader } from '../dataGrid/CustomHeader';
import { Column } from 'react-table';
import { UserAddEditModal } from './UserAddEditModal';
import { User } from '../apps/user-management/users-list/core/_models';
import { useUsersContext } from '../../../context/context';
import { KTSVG } from '../../../_metronic/helpers';
import Swal from 'sweetalert2';

const UsersTable = () => {
  const { gridData, getRoles, getDeparts, getPermissions, deleteUser } = useUsersContext();
  const { params, setParams, getData } = gridData;
  const [openModal, setOpenModal] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    getRoles();
    getDeparts();
    getPermissions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRoleLabel = (role: string) => {
    if (role === 'admin-fm') return 'Admin FM';
    if (role === 'admin') return 'Admin';
    if (role === 'departments') return 'Departments';
    return role;
  };

  const usersColumns: ReadonlyArray<Column<User>> = [
    {
      Header: props => (
        <CustomHeader tableProps={props} title='ID' className='min-w-150px' params={params} setParams={setParams} />
      ),
      id: 'id',
      Cell: ({ ...props }) => props.data[props.row.index].id,
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Nume' className='min-w-150px' params={params} setParams={setParams} />
      ),
      id: 'first_name',
      Cell: ({ ...props }) => props.data[props.row.index].first_name,
    },
    {
      Header: props => (
        <CustomHeader
          tableProps={props}
          title='Prenume'
          className='min-w-150px'
          params={params}
          setParams={setParams}
        />
      ),
      id: 'last_name',
      Cell: ({ ...props }) => props.data[props.row.index].last_name,
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Email' className='min-w-150px' params={params} setParams={setParams} />
      ),
      id: 'email',
      Cell: ({ ...props }) => props.data[props.row.index].email,
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Role' className='min-w-120px' params={params} setParams={setParams} />
      ),
      id: 'role',
      Cell: ({ ...props }) => {
        if (props.row.original.role !== 'admin' && props.row.original.role !== 'admin-fm') {
          return props.row.original.role
            ?.replace(/_/g, ' ')
            .replace(/-/g, ' ')
            .replace(/back/g, 'Back')
            .replace(/manager/g, 'Manager')
            .replace(/kaba/g, 'Kaba')
            .replace(/zone/g, 'Zonal')
            .replace(/departments/g, 'Departments')
            .replace(/shop/g, 'Magazin');
        }
        if (props.row.original.regions_director.length) {
          return getRoleLabel(props.row.original.role) + ` - Director Regional`;
        }
        if (props.row.original.regions_director.length) {
          return getRoleLabel(props.row.original.role) + ` - Administrator Regional`;
        }
        return getRoleLabel(props.row.original.role);
      },
    },
    {
      Header: props => (
        <CustomHeader
          tableProps={props}
          title='Regiuni/Magazine/Departamente'
          className='min-w-120px'
          params={params}
          setParams={setParams}
        />
      ),
      id: 'permissions',
      Cell: ({ ...props }) => {
        if (
          props.row.original.role !== 'admin' &&
          props.row.original.role !== 'admin-fm' &&
          props.row.original.role !== 'departments'
        ) {
          return props.row.original.permissions.length
            ? props.row.original.permissions
                .join(',')
                .replace(/Operate Shop/g, '')
                .replace(/No Shops/g, 'Nealocat')
            : 'N/A';
        }
        if (props.row.original.role === 'admin' || props.row.original.role === 'admin-fm') {
          if (props.row.original.regions_director.length) {
            return props.row.original.regions_director.map((q: any) => q.name).join(', ');
          }
          if (props.row.original.regions_administrator.length) {
            return props.row.original.regions_administrator.map((q: any) => q.name).join(', ');
          }
          return props.row.original.role === 'admin' ? 'Admin' : 'Admin FM';
        }

        if (props.row.original.role === 'departments') {
          return props.row.original.departments
            ?.map((q: any) => q?.department_info?.name)
            .filter((q: any) => q)
            .join(', ');
        }
      },
    },
    {
      Header: props => <CustomHeader tableProps={props} title='Actiuni' className='text-end min-w-100px' />,
      id: 'actions',
      Cell: ({ ...props }) => {
        return (
          <div>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => onOpenEdit(props.row.original)}>
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </button>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              onClick={() =>
                Swal.fire({
                  icon: 'error',
                  title: 'Esti sigur?',
                  showCancelButton: true,
                  confirmButtonText: 'Sterge',
                  cancelButtonText: 'Anulare',
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                }).then(result => {
                  if (result.isConfirmed) {
                    deleteUser(props.row.original.id).then(() => {
                      getData(params);
                    });
                  }
                })
              }>
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
            </button>
          </div>
        );
      },
    },
  ];

  const onCloseModal = () => {
    getData(params);
    setOpenModal(false);
    setUserData({});
  };

  const onOpenEdit = (values: SetStateAction<{}>) => {
    setUserData(values);
    setOpenModal(true);
  };

  return (
    <div>
      <UserAddEditModal openModal={openModal} onClose={onCloseModal} data={userData} />
      <DataGrid
        columns={usersColumns}
        useDataContext={useUsersContext}
        addButton={{
          label: 'Adauga utilizator',
          action: () => setOpenModal(true),
        }}
      />
    </div>
  );
};

export { UsersTable };

import React, { useEffect, useState } from 'react';
import { api } from '../api';
import Cookies from 'js-cookie';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loadingAuthProvider, setLoadingAuthProvider] = useState(true);

  const CLIENT_ID = process.env.REACT_APP_API_CLIENT_ID;
  const CLIENT_SECRET = process.env.REACT_APP_API_CLIENT_SECRET;

  useEffect(() => {
    setTimeout(async () => {
      if (Cookies.get('access_token')) {
        await getAuthUser();
      }
      setLoadingAuthProvider(false);
    }, 0);
  }, []);

  // useEffect(() => {
  //   console.log(Cookies.get('access_token'));
  // }, [Cookies.get('access_token')]);

  const postLogin = async data => {
    return await api
      .post('/oauth/token', {
        ...data,
        grant_type: 'password',
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
      })
      .then(response => {
        Cookies.set('access_token', response.data.access_token);
        Cookies.set('refresh_token', response.data.refresh_token);
        return { data: response.data };
      })
      .catch(error => ({ error }));
  };

  const register = async data => {
    return await api
      .post('/api/register', data)
      .then(response => {
        Cookies.set('access_token', response.data.token);
        return { data: response.data };
      })

      .catch(error => ({ error }));
  };

  const forgotPassword = async data => {
    return await api
      .post('/api/forgot-password', data)
      .then(response => {
        return { data: response.data };
      })

      .catch(error => ({ error }));
  };

  const resetPassword = async data => {
    return await api
      .post('/api/reset-password', data)
      .then(response => {
        Cookies.set('access_token', response.data.token_data.accessToken);
        return { data: response.data };
      })
      .catch(error => {
        return { error };
      });
  };

  const getAuthUser = async data => {
    return await api
      .get('/api/current-user')
      .then(response => {
        setUser(response.data);

        return { data: response.data };
      })
      .catch(error => ({ error }));
  };

  const logout = () => {
    Cookies.remove('access_token');
    Cookies.remove('refresh_token');
    window.location.href = '/';
  };

  return (
    <AuthContext.Provider
      value={{
        authUser: user,
        loadingAuthProvider,
        postLogin,
        getAuthUser,
        logout,
        register,
        forgotPassword,
        resetPassword,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

import { FC, PropsWithChildren } from 'react';
import { HeaderProps } from 'react-table';
import { User } from '../apps/user-management/users-list/core/_models';

type Props = {
  tableProps: PropsWithChildren<HeaderProps<User>>;
  onCheckAll?: any;
  gridData?: any;
  checkedData?: any;
};

const GridSelectionHeader: FC<Props> = ({ tableProps, checkedData, onCheckAll, gridData }) => {
  const onHandleSelectAll = (e: any) => {
    if (!isCheckedAll()) {
      onCheckAll([...checkedData, ...gridData.data]);
    } else {
      onCheckAll(checkedData.filter((q: any) => !gridData.data.find((item: any) => item.id === q.id)));
    }
  };

  const isCheckedAll = () => {
    if (!gridData.data.length) {
      return false;
    }

    for (let i = 0; i < gridData.data.length; i++) {
      if (!checkedData?.find((q: any) => q.id === gridData.data[i].id)) {
        return false;
      }
    }
    return true;
  };

  return (
    <th {...tableProps.column.getHeaderProps()} className='w-10px pe-2'>
      <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
        <input className='form-check-input' type='checkbox' checked={isCheckedAll()} onChange={onHandleSelectAll} />
      </div>
    </th>
  );
};

export { GridSelectionHeader };

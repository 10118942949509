/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { DealsTable } from '../../modules/deals/DealsTable';

const DealsWrapper: FC = () => {
  return (
    <>
      <DealsTable />
    </>
  );
};

export { DealsWrapper };

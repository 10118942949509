import { useState } from 'react';
import { DataGrid } from '../dataGrid/DataGrid';
import { useIntl } from 'react-intl';
import { CustomHeader } from '../dataGrid/CustomHeader';
import { Column } from 'react-table';
import { User } from '../apps/user-management/users-list/core/_models';
import { useOffersContext } from '../../../context/context';
import { KTSVG } from '../../../_metronic/helpers';
import Swal from 'sweetalert2';
import { PageTitle } from '../../../_metronic/layout/core';

import { DealAddEditModal } from './DealAddEditModal';

const DealsTable = () => {
  const intl = useIntl();
  const { gridData, getOffer, deleteOffer } = useOffersContext();
  const { params, setParams, getData } = gridData;
  const [dealData, setDealData] = useState({});
  const [openOfferModal, setOpenOfferModal] = useState(false);

  const DealsColumns: ReadonlyArray<Column<User>> = [
    {
      Header: props => (
        <CustomHeader
          tableProps={props}
          title='Partener'
          className='min-w-150px'
          params={params}
          setParams={setParams}
        />
      ),
      id: 'code',
      Cell: ({ ...props }) => props.data[props.row.index].partner.company_name,
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Oficiu' className='min-w-150px' params={params} setParams={setParams} />
      ),
      id: 'name',
      Cell: ({ ...props }) =>
        props.data[props.row.index].office?.name + '(' + (props.data[props.row.index].office?.city?.county ?? '') + ')',
    },
    {
      Header: props => (
        <CustomHeader
          tableProps={props}
          title='Magazine'
          className='min-w-150px'
          params={params}
          setParams={setParams}
        />
      ),
      id: 'shop',
      Cell: ({ ...props }) => (
        <div style={{ maxWidth: '350px' }}>
          {props.data[props.row.index].shops.map((shop: any) => (
            <span className='me-1'>{shop.name}</span>
          ))}
        </div>
      ),
    },

    {
      Header: props => <CustomHeader tableProps={props} title='Actiuni' className='text-end min-w-100px' />,
      id: 'actions',
      Cell: ({ ...props }) => {
        return (
          <div>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => onOpenEditDeal(props.row.original)}>
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </button>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              onClick={() =>
                Swal.fire({
                  icon: 'error',
                  title: 'Esti sigur?',
                  showCancelButton: true,
                  confirmButtonText: 'Sterge',
                  cancelButtonText: 'Anulare',
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                }).then(result => {
                  if (result.isConfirmed) {
                    deleteOffer(props.row.original.id).then(() => {
                      getData(params);
                    });
                  }
                })
              }>
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
            </button>
          </div>
        );
      },
    },
  ];

  const onCloseModal = () => {
    getData(params);
    setOpenOfferModal(false);
    setDealData({});
  };

  const onOpenEditDeal = async (values: { id: any }) => {
    const offer = await getOffer(values?.id);
    setDealData(offer.data);
    setOpenOfferModal(true);
  };

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.OFERTE' })}</PageTitle>

      <div className=''>
        <div className=''>
          <div>
            {openOfferModal && <DealAddEditModal openModal={openOfferModal} onClose={onCloseModal} offer={dealData} />}
            <DataGrid
              columns={DealsColumns}
              useDataContext={useOffersContext}
              addButton={{
                label: 'Adaugare oferta',
                action: () => setOpenOfferModal(true),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { DealsTable };

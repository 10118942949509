import React, { useState } from 'react';
import { api } from '../api';

export const MaterialsContext = React.createContext();

export const MaterialsProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [gridParams, setGridParams] = useState({
    page: 1,
    materialCategoryId: 0,
    limit: 10,
  });
  const [materials, setMaterials] = useState({
    data: [],
  });

  const getMaterials = async query => {
    setLoading(true);
    return await api
      .get('/api/material', {
        params: query,
      })
      .then(response => {
        setMaterials(response.data);
        setLoading(false);
        return response.data;
      })
      .catch(error => {
        setLoading(false);
        return { error };
      });
  };

  const getCategoryMaterialsForFilters = async query => {
    return await api.get('/api/material-category', {
      params: { ...query, limit: 1000 },
    });
  };

  const getCategoryMaterialDetalisForFilters = async id => {
    return await api.get(`/api/material-category/${id}`, {});
  };

  const getMaterial = async id => {
    return await api
      .get(`/api/material/${id}`)
      .then(response => ({ data: response.data }))
      .catch(error => ({ error }));
  };

  const searchMaterials = async query => {
    return await api
      .get(`/api/material/search`, {
        params: query,
      })
      .then(response => response.data)
      .catch(error => ({ error }));
  };

  const addMaterial = async data => {
    return await api
      .post('/api/material', data)
      .then(response => ({ data: response.data }))
      .catch(error => ({ error }));
  };

  const editMaterial = async (id, data) => {
    return await api
      .put(`/api/material/${id}`, data)
      .then(response => {
        return { data: response.data };
      })
      .catch(error => {
        return { error };
      });
  };

  const deleteMaterial = async id => {
    return await api
      .delete(`/api/material/${id}`)
      .then(response => ({ data: response.data }))
      .catch(error => ({ error }));
  };

  return (
    <MaterialsContext.Provider
      value={{
        materials,
        getMaterials,
        getMaterial,
        searchMaterials,
        addMaterial,
        editMaterial,
        deleteMaterial,
        getCategoryMaterialsForFilters,
        getCategoryMaterialDetalisForFilters,
        gridData: {
          getData: getMaterials,
          isLoading: loading,
          data: materials?.data || [],
          links: materials?.links || [],
          params: gridParams,
          setParams: setGridParams,
        },
      }}>
      {children}
    </MaterialsContext.Provider>
  );
};

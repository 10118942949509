/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
// import { useIntl } from 'react-intl';
// import { PageTitle } from '../../../_metronic/layout/core';
import { CategoryMaterialsTable } from '../../modules/materials/CategoryMaterialsTable';

type Props = {
  level: number;
};

const CategoryMaterialsWrapper: FC<Props> = props => {
  // const intl = useIntl();

  return (
    <>
      <CategoryMaterialsTable {...props} />
    </>
  );
};

export { CategoryMaterialsWrapper };

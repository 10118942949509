import { FC, useState } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { KTSVG } from '../../../_metronic/helpers';

import { useFormik } from 'formik';
import { useCurrencyContext } from '../../../context/context';

import DatePicker from 'react-datepicker';
import Select from 'react-select';

import 'react-datepicker/dist/react-datepicker.css';

import clsx from 'clsx';

type Props = {
  openModal: any;
  onClose: any;
  currency: any;
};

const editCurrencySchema = Yup.object().shape({
  name: Yup.string().required('Numele este obligatoriu!'),
  value: Yup.number().required('Valoarea este obligatorie!'),
  default: Yup.boolean().oneOf([true, false]).default(false),
});

const CurrencyAddEditModal: FC<Props> = ({ openModal, onClose, currency }) => {
  const isEdit = Object.values(currency).length > 0;
  const currencyOptions = [
    { label: 'RON', value: 'RON' },
    { label: 'EUR', value: 'EUR' },
    { label: 'USD', value: 'USD' },
  ];
  const { addCurrency, editCurrency } = useCurrencyContext();

  const [editData, setEditData] = useState({
    ...currency,
    name: currency.name || '',
    symbol: currency.symbol || '',
    value: currency.value || '',
    default: currency.default || false,
    id: currency ? currency.id : null,
    date: currency.date,
  });

  const formik = useFormik({
    initialValues: editData,
    validationSchema: editCurrencySchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const data = {
        name: values.name,
        symbol: editData.symbol,
        value: values.value,
        default: editData.symbol === 'RON',
        date: editData.date ? moment(editData.date).format('YYYY-MM-DD') : null,
      };
      let res: any = null;
      if (isEdit) {
        res = await editCurrency(currency.id, data);
      } else {
        res = await addCurrency(data);
      }

      setSubmitting(false);

      if (res.error) {
        if (res.error.response.data) {
          Object.keys(res.error.response.data).forEach(name => {
            formik.setErrors({
              ...formik.errors,
              [name]: res.error.response.data[name][0],
            });
          });
        }
      } else {
        onClose && onClose();
      }
    },
  });

  return (
    <>
      <div className='modal fade show d-block' id='kt_modal_add_user' role='dialog' tabIndex={-1} aria-modal='true'>
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>{isEdit ? 'Editare valuta' : 'Adaugare valuta'}</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => onClose()}
                style={{ cursor: 'pointer' }}>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <form onSubmit={formik.handleSubmit} id='kt_modal_add_user_form' className='form' autoComplete={'off'}>
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Symbol</label>
                  <Select
                    onChange={opt => {
                      formik.setFieldValue('name', opt?.value);
                      setEditData({
                        ...editData,
                        symbol: opt?.value,
                      });
                    }}
                    options={currencyOptions}
                    placeholder='Symbol'
                    name='symbol'
                    value={currencyOptions.find(q => q.value === editData.currency)}
                    className='form-control-async-select'
                  />

                  {formik.touched.symbol && formik.errors.symbol && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{typeof formik.errors.symbol === 'string' && formik.errors.symbol}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Nume</label>
                  <input
                    placeholder='Nume'
                    {...formik.getFieldProps('name')}
                    type='text'
                    name='name'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.name && formik.errors.name },
                      {
                        'is-valid': formik.touched.name && !formik.errors.name,
                      }
                    )}
                    autoComplete='off'
                    value={formik.values.name}
                    disabled={formik.isSubmitting}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{typeof formik.errors.name === 'string' && formik.errors.name}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Valoare</label>
                  <input
                    placeholder='Valoare'
                    {...formik.getFieldProps('value')}
                    type='text'
                    name='value'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.value && formik.errors.value },
                      {
                        'is-valid': formik.touched.value && !formik.errors.value,
                      }
                    )}
                    autoComplete='off'
                    value={formik.values.value}
                    disabled={formik.isSubmitting}
                  />
                  {formik.touched.value && formik.errors.value && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{typeof formik.errors.value === 'string' && formik.errors.value}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Data</label>
                  <DatePicker
                    selected={editData.date ? new Date(editData.date) : null}
                    onChange={value =>
                      setEditData({ ...editData, date: value ? moment(value).format('YYYY-MM-DD') : null })
                    }
                    placeholderText='Data'
                    dateFormat='yyyy-MM-dd'
                    className='form-control form-control-solid form-select-sm form-select mb-3 mb-lg-0'
                  />
                  {formik.errors.date && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{typeof formik.errors.date === 'string' && formik.errors.date}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <input
                    {...formik.getFieldProps('default')}
                    type='checkbox'
                    name='default'
                    autoComplete='off'
                    checked={editData.symbol === 'RON'}
                    disabled
                  />
                  <label className='fw-bold fs-6 mb-2' style={{ paddingLeft: 10 }}>
                    Default
                  </label>
                </div>
                <button type='submit' className='btn btn-sm btn-primary' disabled={formik.isSubmitting}>
                  Salveaza
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  );
};

export { CurrencyAddEditModal };

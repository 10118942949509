import React, { useEffect } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { useAuthContext } from '../../../../context/context';
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components';

const initialValues = {
  password: '',
  password_confirmation: '',
  token: '',
};

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string().min(8, 'Minimum 8 symbols').max(50, 'Maximum 50 symbols').required('Password is required'),
  password_confirmation: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
});

export function ResetPassword() {
  const { resetPassword } = useAuthContext();
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  const setPassword = queryParams.get('setPassword');

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: async values => {
      const data = {
        password: values.password,
        password_confirmation: values.password_confirmation,
        token: token,
      };
      try {
        const res = await resetPassword(data);
        if (!res.error) {
          setTimeout(() => {
            window.location.href = '/reports';
          }, 10);
        }
        if (res.error) {
          if (res.error.response.data) {
            Object.keys(res.error.response.data).forEach(name => {
              formik.setErrors({
                ...formik.errors,
                [name]: res.error.response.data[name][0],
              });
            });
          }
        }
      } catch (error) {}
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}>
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>{`${setPassword ? 'Set Password' : 'Reset Password'}`}</h1>
          {/* end::Title */}
        </div>

        {/* begin::Title */}

        {/* begin::Form group */}
        <div className='mb-10 fv-row' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6'>Password</label>
            <div className='position-relative mb-3'>
              <input
                type='password'
                placeholder='Password'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* begin::Meter */}
            <div className='d-flex align-items-center mb-3' data-kt-password-meter-control='highlight'>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
            {/* end::Meter */}
          </div>
          <div className='text-muted'>Use 8 or more characters with a mix of letters, numbers & symbols.</div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
          <input
            type='password'
            placeholder='Password confirmation'
            autoComplete='off'
            {...formik.getFieldProps('password_confirmation')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password_confirmation && formik.errors.password_confirmation,
              },
              {
                'is-valid': formik.touched.password_confirmation && !formik.errors.password_confirmation,
              }
            )}
          />
          {formik.touched.password_confirmation && formik.errors.password_confirmation && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password_confirmation}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button type='submit' id='kt_password_reset_submit' className='btn btn-lg btn-primary fw-bolder me-4'>
            <span className='indicator-label'>Submit</span>
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}>
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  );
}

import { FC, useState } from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { useSearchParams } from 'react-router-dom';
import { MaterialCategoryItemMultiple } from './MaterialCategoryItemMultiple';

type Props = {
  onFilterMaterials: any;
  params?: any;
  level: number;
};

const MaterialMultipleCategoryFilter: FC<Props> = ({ onFilterMaterials, params, level }) => {
  const [linkParams] = useSearchParams();
  const [appParams, setAppParams] = useState([]);
  const [options, setOptions] = useState([
    {
      parent_id: linkParams.get('parent_id'),
    },
  ]);

  const resetData = () => {
    setOptions([]);
    setTimeout(() => {
      setOptions([
        {
          parent_id: null,
        },
      ]);
    }, 0);
    onFilterMaterials({ materialCategoryId: null, parent_id: null });
  };

  const filterData = () => {
    const data = typeof appParams === 'object' ? appParams.map((q: any) => q.value).join(',') : null;

    onFilterMaterials({
      materialCategoryId: data,
      parent_id: data,
    });
  };

  return (
    <>
      <button
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'>
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-700px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5 d-flex flex-wrap' data-kt-user-table-filter='form'>
          {options.map((q, i) => (
            <MaterialCategoryItemMultiple
              key={i}
              level={level}
              updateValue={(value: any) => {
                setAppParams(value);
              }}
              params={q}
            />
          ))}

          <div className='d-flex align-items-center mb-10 me-6 '>
            <button
              type='button'
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'>
              Reset
            </button>
            <button
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'>
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export { MaterialMultipleCategoryFilter };

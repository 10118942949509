import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '../dataGrid/DataGrid';
import { CustomHeader } from '../dataGrid/CustomHeader';
import { Column } from 'react-table';
import { User } from '../apps/user-management/users-list/core/_models';
import { useReportsContext } from '../../../context/context';
import { useCurrencyContext } from '../../../context/context';

import { Filter } from '../filter/Filter';
import { GridSelectionHeader } from './GridSelectionHeader';
import { GridSelectionCell } from './GridSelectionCell';

const ReportsTable = () => {
  const { gridData, getExcelReportFile } = useReportsContext();
  const { getCurrency, currency } = useCurrencyContext();
  const { params, setParams } = gridData;
  const [checkedReportsIds, setCheckedReportsIds] = useState<any>({ offers: [] });
  const [downloading, setDownloading] = useState(false);
  const navigate = useNavigate();

  const ReportsColumns: ReadonlyArray<Column<User>> = [
    {
      Header: props => (
        <GridSelectionHeader
          gridData={gridData}
          tableProps={props}
          checkedData={checkedReportsIds.offers}
          onCheckAll={(newData: any) =>
            setCheckedReportsIds({
              offers: newData,
            })
          }
        />
      ),
      id: 'selection',
      Cell: ({ ...props }) => (
        <GridSelectionCell
          id={props.data[props.row.index].id}
          allChecked={false}
          checked={checkedReportsIds?.offers?.find((item: any) => props.row.original?.id === item?.id)}
          onCheckReport={() => onHandleCheckReport(props.row.original)}
        />
      ),
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Nr' className='min-w-50px' params={params} setParams={setParams} />
      ),
      id: 'id',
      Cell: ({ ...props }) => props.data[props.row.index].id,
    },
    {
      Header: props => <CustomHeader tableProps={props} title='Material' params={params} setParams={setParams} />,
      id: 'material',
      Cell: ({ ...props }) => props.data[props.row.index].material?.name,
    },
    {
      Header: props => (
        <CustomHeader
          tableProps={props}
          title='Partener'
          className='min-w-150px'
          params={params}
          setParams={setParams}
        />
      ),
      id: 'company_name',
      Cell: ({ ...props }) => props.data[props.row.index].offer?.partner?.company_name,
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Status' className='min-w-150px' params={params} setParams={setParams} />
      ),
      id: 'status',
      Cell: ({ ...props }) => props.data[props.row.index].status,
    },
    {
      Header: props => (
        <CustomHeader
          tableProps={props}
          title='ID oferta'
          className='min-w-150px'
          params={params}
          setParams={setParams}
        />
      ),
      id: 'offers_id',
      Cell: ({ ...props }) => props.data[props.row.index].offers_id,
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Data' className='min-w-120px' params={params} setParams={setParams} />
      ),
      id: 'date',
      Cell: ({ ...props }) => props.data[props.row.index].offer.created_at.slice(0, 10),
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='U.M.' className='min-w-120px' params={params} setParams={setParams} />
      ),
      id: 'unit',
      Cell: ({ ...props }) => props.data[props.row.index].unit.symbol ?? props.data[props.row.index].unit.name,
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Pret' className='min-w-120px' params={params} setParams={setParams} />
      ),
      id: 'priceConverted',
      Cell: ({ ...props }) =>
        `${Number(props.data[props.row.index].priceConverted).toFixed(2)} ${
          props.data[props.row.index].priceConvertedCurrency
        }`,
    },
    {
      Header: props => (
        <CustomHeader
          tableProps={props}
          title='Pret Original'
          className='min-w-120px'
          params={params}
          setParams={setParams}
        />
      ),
      id: 'price',
      Cell: ({ ...props }) => `${props.data[props.row.index].price} ${props.data[props.row.index].currency?.symbol}`,
    },
  ];

  useEffect(() => {
    getCurrency();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHandleCheckReport = (offer: any) => {
    if (!checkedReportsIds?.offers?.find((item: any) => offer?.id === item?.id)) {
      setCheckedReportsIds({
        offers: [...checkedReportsIds?.offers, offer],
      });
    } else {
      setCheckedReportsIds({ offers: checkedReportsIds?.offers?.filter((item: any) => item.id !== offer.id) });
    }
  };

  const onHandleFilter = (data: any) => {
    const newData = {
      shops: data?.selectedShops?.toString(),
      currencyDate: data?.currencyDate,
      products: data?.selectedProducts,
      mesurementUnits: data?.selectedMesurementUnits?.toString(),
      dateFrom: data?.dateFrom,
      dateTo: data?.dateTo,
      priceFrom: data?.priceFrom,
      priceTo: data?.priceTo,
      currency: data?.selectedCurrency?.label || params?.currency,
      status: data?.status?.value,
    };
    setParams({ ...params, ...newData, page: 1 });
  };

  const onHandleOpenChart = () => {
    const query = {
      offers: checkedReportsIds.offers.map((item: any) => ({ materialId: item.material_id, offerId: item.offers_id })),
    };

    const checkedOffers = { ...query, currency: params?.currency, currencyDate: params?.currencyDate };
    const code = JSON.stringify(checkedOffers);
    const encode = encodeURIComponent(code);
    navigate(`/reports/chart/${encode}`);
  };

  const onHandleDownloadReport = () => {
    setDownloading(true);
    getExcelReportFile(params).finally(() => setDownloading(false));
  };

  return (
    <div>
      <DataGrid
        columns={ReportsColumns}
        useDataContext={useReportsContext}
        aditionalActions={
          <div>
            <Filter onFilterReports={onHandleFilter} params={params} currencies={currency.data} />
            <button
              className='btn btn-light-primary me-3'
              disabled={downloading}
              onClick={() => onHandleDownloadReport()}>
              Descarca Raport
              {downloading && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
            </button>

            <button
              className='btn btn-light-primary me-3'
              onClick={() => onHandleOpenChart()}
              disabled={checkedReportsIds?.offers?.length === 0}>
              Deschide Graficul
            </button>
          </div>
        }
      />
    </div>
  );
};

export { ReportsTable };

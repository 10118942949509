import { FC, useState, useEffect } from 'react';

import moment from 'moment';
import Swal from 'sweetalert2';

import { useMaterialsContext, useCurrencyContext, useMeasurementUnitsContext } from '../../../context/context';
import { KTSVG } from '../../../_metronic/helpers';

import DatePicker from 'react-datepicker';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';

type Props = {
  setSubArticle: any;
  product: any;
  errors?: any;
  subArticleIndex: number;
  isEdit: boolean;
  onDeleteSubArticle: any;
};
const StatusOptions = [
  {
    label: 'Offer',
    value: 'OFFER',
  },
  {
    label: 'Contract',
    value: 'CONTRACT',
  },
  {
    label: 'Expired Contract',
    value: 'EXPIRED_CONTRACT',
  },
  {
    label: 'Terminated Contract',
    value: 'TERMINATED_CONTRACT',
  },
];

const SubArticleForm: FC<Props> = ({ setSubArticle, product, errors, subArticleIndex, isEdit, onDeleteSubArticle }) => {
  const { searchMaterials } = useMaterialsContext();
  const { getCurrency, currency } = useCurrencyContext();
  const { measurementUnits } = useMeasurementUnitsContext();

  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    if (isEdit) {
      getCurrency({ date: product.date });
    }
  };

  const onHandleChange = (e: any) => {
    setSubArticle({ ...product, [e.target.name]: e.target.value });
  };

  const onHandleSelect = (item: any, target: any) => {
    setSubArticle({ ...product, [target.name]: item });
  };

  const onChangeDate = async (value: any) => {
    const date = moment(value).format('YYYY-MM-DD');
    setSelectedDate(value);
    setSubArticle({ ...product, date: date });
    await getCurrency({ date: date });
  };

  const onHandleDeleteSubArticle = () => {
    onDeleteSubArticle(subArticleIndex);
  };

  const onHandleSearchMaterials = (inputValue: string) =>
    new Promise<any>(async resolve => {
      const materials = await searchMaterials({ q: inputValue });

      resolve(
        materials?.map((q: any) => ({
          label:
            q.level_one_name + ' -> ' + q.level_two_name + ' -> ' + q.level_three_name + ' -> ' + q.level_four_name,
          value: q.level_four_id,
        })) || []
      );
    });

  const defaultMaterial =
    isEdit && product?.material_select
      ? product.material_select?.map((q: any) => ({
          label:
            q.level_one_name + ' -> ' + q.level_two_name + ' -> ' + q.level_three_name + ' -> ' + q.level_four_name,
          value: q.level_four_id,
        }))
      : undefined;

  const defaultStatus =
    isEdit && product?.status ? StatusOptions?.filter((q: any) => q.value === product.status) : undefined;

  const defaultUnit =
    isEdit && product?.unit_id
      ? {
          label: product?.measurement_unit?.name + '(' + product?.measurement_unit?.symbol + ')',
          value: product?.measurement_unit?.id,
        }
      : undefined;

  const defaultCurrency =
    isEdit && product?.currency_id ? { label: product?.currency?.symbol, value: product?.currency?.id } : undefined;

  return (
    <div className='p-4 mb-5 border rounded d-flex align-items-center'>
      <div className='form w-100 d-flex flex-wrap'>
        <div className='col-sm-7 mb-7 px-2'>
          <label className='fw-bold fs-6 mb-2'>Material</label>
          <AsyncSelect
            loadOptions={onHandleSearchMaterials}
            onChange={onHandleSelect}
            value={defaultMaterial?.length ? defaultMaterial[0] : product.material_id}
            placeholder='Material'
            name='material_id'
            isDisabled={isEdit && product?.id}
            defaultOptions={defaultMaterial}
            className='form-control-async-select'
          />
          {errors?.[`products.${subArticleIndex}.material_id`] && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.[`products.${subArticleIndex}.material_id`]?.join(' ')}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-sm-5 mb-7 px-2'>
          <label className='fw-bold fs-6 mb-2'>Unitate de masura</label>
          <Select
            onChange={onHandleSelect}
            options={measurementUnits?.map((q: any) => ({ label: q.name + '(' + q.symbol + ')', value: q.id }))}
            placeholder='Unitate de masura'
            name='unit_id'
            isDisabled={isEdit && product?.id}
            value={isEdit && product?.measurement_unit ? defaultUnit : product.unit_id}
            className='form-control-async-select'
          />
          {errors?.[`products.${subArticleIndex}.unit_id`] && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.[`products.${subArticleIndex}.unit_id`]?.join(' ')}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-sm-3 mb-7 px-2'>
          <label className='fw-bold fs-6 mb-2'>Data</label>
          <DatePicker
            selected={selectedDate}
            onChange={onChangeDate}
            value={product.date}
            placeholderText='Data'
            // disabled={isEdit && product?.id}
            dateFormat='dd/MM/yyyy'
            maxDate={new Date()}
            className='form-control-date-piker'
          />
          {errors?.[`products.${subArticleIndex}.date`] && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.[`products.${subArticleIndex}.date`]?.join(' ')}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-sm-3 mb-7 px-2'>
          <label className='fw-bold fs-6 mb-2'>Valuta</label>
          <Select
            onChange={onHandleSelect}
            options={currency?.data?.map((q: any) => ({ label: q.symbol, value: q.id }))}
            placeholder='Valuta'
            name='currency_id'
            value={isEdit && product?.currency ? defaultCurrency : product.currency_id}
            isDisabled={isEdit ? false : !selectedDate}
            className='form-control-async-select'
          />
          {errors?.[`products.${subArticleIndex}.currency_id`] && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.[`products.${subArticleIndex}.currency_id`]?.join(' ')}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-sm-3 mb-7 px-2'>
          <label className='fw-bold fs-6 mb-2'>Pret</label>
          <input
            placeholder='Pret'
            type='number'
            name='price'
            className='form-control form-control-solid mb-3 mb-lg-0'
            autoComplete='off'
            value={product.price || ''}
            onChange={onHandleChange}
          />
          {errors?.[`products.${subArticleIndex}.price`] && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.[`products.${subArticleIndex}.price`]?.join(' ')}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-sm-3 mb-7 px-2'>
          <label className='fw-bold fs-6 mb-2'>Statut</label>
          <Select
            onChange={onHandleSelect}
            placeholder='Statut'
            name='status'
            options={StatusOptions?.map((q: any) => ({ label: q.label, value: q.value }))}
            value={defaultStatus?.length ? defaultStatus[0] : product.status}
            className='form-control-async-select'
          />
          {errors?.[`products.${subArticleIndex}.status`] && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.[`products.${subArticleIndex}.status`]?.join(' ')}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        onClick={() =>
          Swal.fire({
            icon: 'error',
            title: 'Esti sigur?',
            showCancelButton: true,
            confirmButtonText: 'Sterge',
            cancelButtonText: 'Anulare',
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
          }).then(result => {
            if (result.isConfirmed) {
              onHandleDeleteSubArticle();
            }
          })
        }
        role='button'>
        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
      </div>
    </div>
  );
};

export { SubArticleForm };

/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { MaterialsTable } from '../../modules/materials/MaterialsTable';

type Props = {
  level: number;
};

const MaterialsWrapper: FC<Props> = props => {
  return (
    <>
      <MaterialsTable {...props} />
    </>
  );
};

export { MaterialsWrapper };

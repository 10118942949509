const ListLoading = () => {
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  };

  return (
    <div
      style={{
        background: '#00000042',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 999,
        position: 'fixed',
      }}>
      <div style={{ ...styles, position: 'absolute', textAlign: 'center' }}>Processing...</div>
    </div>
  );
};

export { ListLoading };

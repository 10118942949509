import { SetStateAction, useState } from 'react';
import moment from 'moment';
import { DataGrid } from '../dataGrid/DataGrid';
import { CustomHeader } from '../dataGrid/CustomHeader';
import { Column } from 'react-table';
import { CurrencyAddEditModal } from './CurrencyAddEditModal';
import { User } from '../apps/user-management/users-list/core/_models';
import { useCurrencyContext } from '../../../context/context';
import { KTSVG } from '../../../_metronic/helpers';
import { CurrencyFilter } from '../filter/CurrencyFilter';

const CurrencyTable = () => {
  const { gridData } = useCurrencyContext();
  const { params, setParams, getData } = gridData;
  const [openModal, setOpenModal] = useState(false);
  const [currencyData, setCurrencyData] = useState({});

  const ReportsColumns: ReadonlyArray<Column<User>> = [
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Nr' className='min-w-50px' params={params} setParams={setParams} />
      ),
      id: 'id',
      Cell: ({ ...props }) => props.data[props.row.index].id,
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Symbol' className='min-w-150px' params={params} setParams={setParams} />
      ),
      id: 'symbol',
      Cell: ({ ...props }) => props.data[props.row.index].symbol,
    },
    {
      Header: props => (
        <CustomHeader
          tableProps={props}
          title='Date'
          className='min-w-50px'
          params={params}
          setParams={setParams}
          filters={<CurrencyFilter onFilterCurrency={onHandleFilter} params={params} />}
        />
      ),
      id: 'date',
      Cell: ({ ...props }) => props.data[props.row.index].date,
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Value' className='min-w-150px' params={params} setParams={setParams} />
      ),
      id: 'value',
      Cell: ({ ...props }) => props.data[props.row.index].value,
    },

    {
      Header: props => <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />,
      id: 'actions',
      Cell: ({ ...props }) => {
        return (
          <div>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => onOpenEdit(props.row.original)}>
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </button>
            {/* <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              onClick={() =>
                Swal.fire({
                  icon: 'error',
                  title: 'Esti sigur?',
                  showCancelButton: true,
                  confirmButtonText: 'Sterge',
                  cancelButtonText: 'Anulare',
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                }).then(result => {
                  if (result.isConfirmed) {
                    deleteCurrency(props.row.original.id).then(() => {
                      getData(params);
                    });
                  }
                })
              }>
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
            </button> */}
          </div>
        );
      },
    },
  ];

  const onCloseModal = () => {
    getData(params);
    setOpenModal(false);
    setCurrencyData({});
  };

  const onOpenEdit = (values: SetStateAction<{}>) => {
    setCurrencyData(values);
    setOpenModal(true);
  };

  const onHandleFilter = (value: { dateFrom: any; dateTo: any }) => {
    console.log(value);
    const newDateFrom = value?.dateFrom ? moment(value.dateFrom).format('YYYY-MM-DD') : '';
    const newDateTo = value?.dateTo ? moment(value.dateTo).format('YYYY-MM-DD') : '';
    setParams({ ...params, dateFrom: newDateFrom, dateTo: newDateTo, page: 1 });
  };

  return (
    <div>
      {openModal && <CurrencyAddEditModal openModal={openModal} onClose={onCloseModal} currency={currencyData} />}
      <DataGrid
        columns={ReportsColumns}
        useDataContext={useCurrencyContext}
        addButton={{
          label: 'Adaugare curs valutar',
          action: () => setOpenModal(true),
        }}
      />
    </div>
  );
};

export { CurrencyTable };

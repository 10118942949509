/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import { ReportsTable } from '../../modules/reportsTable/ReportsTable';

const ReportsPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className=''>
      <div className=''>
        <ReportsTable />
      </div>
    </div>
  </>
);

const ReportsWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.REPORTS' })}</PageTitle>
      <ReportsPage />
    </>
  );
};

export { ReportsWrapper };

import { FC, useState, useEffect } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import Swal from 'sweetalert2';

import { useShopsContext, usePartnersContext } from '../../../context/context';
import { KTSVG } from '../../../_metronic/helpers';

import AsyncSelect from 'react-select/async';

type Props = {
  setOffice: any;
  office: any;
  errors?: any;
  officeIndex: number;
  isEdit: boolean;
  removeItem: any;
};

const OfficesForm: FC<Props> = ({ removeItem, setOffice, office, errors, officeIndex, isEdit }) => {
  const { cityRegionList } = useShopsContext();
  const { shops } = usePartnersContext();

  const [selectedDepartment, setSelectedDepartment] = useState([]);

  useEffect(() => {
    if (isEdit) {
      const selectedDepartments = office?.shops?.map((shop: any) => shop.id);
      setSelectedDepartment(selectedDepartments);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHandleChange = (e: any) => {
    setOffice({ ...office, [e.target.name]: e.target.value });
  };

  const onHandleSearchCity = (inputValue: string) =>
    new Promise<any>(async resolve => {
      const cities = await cityRegionList({ q: inputValue });

      resolve(
        cities?.data?.map((q: any) => ({
          label: (q.region?.name ?? '') + ' Jud.' + q.county + ' - ' + q.name,
          value: q.id,
        })) || []
      );
    });

  const onHandleSelectCity = (city: any) => {
    setOffice({ ...office, city: city.value });
  };

  const onChangeMultiSelect = (value: any) => {
    setSelectedDepartment(value.map((q: any) => q.value));
    const selectedShops = value.map((q: any) => ({ shop_id: q.value }));
    setOffice({ ...office, shops: selectedShops });
  };

  const defaultCity =
    isEdit && office?.city?.id
      ? [office.city]?.map((q: any) => ({
          label: (q.region?.name ?? '') + ' Jud.' + q.county + ' - ' + q.name,
          value: q.id,
        }))
      : undefined;

  return (
    <div className='p-4 mb-5 border rounded d-flex align-items-center'>
      <div className='form d-flex flex-wrap'>
        <div className='col-sm-4 mb-7 px-2'>
          <label className='fw-bold fs-6 mb-2'>Nume oficiu</label>
          <input
            placeholder='Nume oficiu'
            type='text'
            name='name'
            className='form-control form-control-solid mb-3 mb-lg-0'
            autoComplete='off'
            onChange={onHandleChange}
            value={office.name}
          />
          {errors?.[`offices.${officeIndex}.name`] && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.[`offices.${officeIndex}.name`]?.join(' ')}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-sm-4 mb-7 px-2'>
          <label className='fw-bold fs-6 mb-2'>Email</label>
          <input
            placeholder='Email'
            type='text'
            name='email'
            className='form-control form-control-solid mb-3 mb-lg-0'
            autoComplete='off'
            value={office.email}
            onChange={onHandleChange}
          />
          {errors?.[`offices.${officeIndex}.email`] && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.[`offices.${officeIndex}.email`]?.join(' ')}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-sm-4 mb-7 px-2'>
          <label className='fw-bold fs-6 mb-2'>Telefon</label>
          <input
            placeholder='Telefon'
            type='text'
            name='phone'
            className='form-control form-control-solid mb-3 mb-lg-0'
            autoComplete='off'
            value={office.phone}
            onChange={onHandleChange}
          />
          {errors?.[`offices.${officeIndex}.phone`] && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.[`offices.${officeIndex}.phone`]?.join(' ')}</span>
              </div>
            </div>
          )}
        </div>
        <div className='w-50 mb-7 px-2'>
          <label className='fw-bold fs-6 mb-2'>Oras</label>
          <AsyncSelect
            loadOptions={onHandleSearchCity}
            onChange={onHandleSelectCity}
            defaultValue={defaultCity?.length ? defaultCity[0] : null}
            placeholder='Oras'
            name='city_id'
            defaultOptions={defaultCity}
            className='form-control-async-select'
          />
          {errors?.[`offices.${officeIndex}.city`] && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.[`offices.${officeIndex}.city`]?.join(' ')}</span>
              </div>
            </div>
          )}
        </div>
        <div className='w-50 mb-7 px-2'>
          <label className='required fw-bold fs-6 mb-2'>Magazin</label>
          <MultiSelect
            hasSelectAll={false}
            options={[
              ...shops
                ?.filter((q: any) => selectedDepartment?.find((selected: any) => selected === q.id))
                .map((q: any) => ({
                  label: q.name + (q.city ? ' - ' + q.city.name : ''),
                  value: q.id,
                })),
              ...shops
                ?.filter((q: any) => !selectedDepartment?.find((selected: any) => selected === q.id))
                .map((q: any) => ({
                  label: q.name + (q.city ? ' - ' + q.city.name : ''),
                  value: q.id,
                })),
            ]}
            value={shops
              ?.filter((q: any) => selectedDepartment?.find((selected: any) => selected === q.id))
              .map((q: any) => ({
                label: q.name + (q.city ? ' - ' + q.city.name : ''),
                value: q.id,
              }))}
            onChange={onChangeMultiSelect}
            labelledBy='Select'
            dropdown-heading
            className='form-control-async-select-multiple'
          />
          {errors?.[`offices.${officeIndex}.shops`] && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.[`offices.${officeIndex}.shops`]?.join(' ')}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {!office.id && (
        <div
          onClick={() =>
            Swal.fire({
              icon: 'error',
              title: 'Esti sigur?',
              showCancelButton: true,
              confirmButtonText: 'Sterge',
              cancelButtonText: 'Anulare',
              confirmButtonColor: '#d33',
              cancelButtonColor: '#3085d6',
            }).then(result => {
              if (result.isConfirmed) {
                removeItem();
              }
            })
          }
          role='button'>
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </div>
      )}
    </div>
  );
};

export { OfficesForm };

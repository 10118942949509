import React, { FC, useEffect } from 'react';
import { useTable, ColumnInstance, Row } from 'react-table';
import { CustomHeaderColumn } from '../apps/user-management/users-list/table/columns/CustomHeaderColumn';
import { CustomRow } from '../apps/user-management/users-list/table/columns/CustomRow';
import { User } from '../apps/user-management/users-list/core/_models';
import { ListLoading } from './ListLoading';
import { Pagination } from './Pagination';
import { KTCardBody } from '../../../_metronic/helpers';
import { KTCard, KTSVG } from '../../../_metronic/helpers';

type Props = {
  columns: any;
  useDataContext: any;
  addButton?: any;
  aditionalActions?: any;
  childs?: any;
  collapsedItem?: any;
  itemId?: any;
};

const DataGrid: FC<Props> = ({ columns, useDataContext, addButton, childs, aditionalActions, collapsedItem }) => {
  const { gridData } = useDataContext();
  const { isLoading, data, params, getData, links, setParams } = gridData;

  const pathname = window.location.pathname;

  useEffect(() => {
    if (!params.limit) return;

    getData(params);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, pathname]);

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search...'
                value={params?.q ?? ''}
                onChange={e => setParams({ ...params, q: e.target.value, page: 1 })}
              />
            </div>
            {/* end::Search */}
          </div>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              {aditionalActions}
              {addButton && (
                <button type='button' className='btn btn-primary' onClick={() => addButton.action()}>
                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                  {addButton.label}
                </button>
              )}
            </div>
          </div>
        </div>
        <KTCardBody className='py-4'>
          <div className='table-responsive'>
            <table
              id='kt_table_users'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
              {...getTableProps()}>
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                  {headers.map((column: ColumnInstance<User>) => {
                    return <CustomHeaderColumn key={column.id} column={column} />;
                  })}
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                {rows.length > 0 ? (
                  rows.map((row: Row<User>, i) => {
                    prepareRow(row);
                    return (
                      <React.Fragment key={`grid-row-${i}`}>
                        <CustomRow row={row} key={`row-${i}-${row.id}`} />

                        {collapsedItem?.id === row.original.id && (
                          <tr key={i}>
                            <td colSpan={6}>{childs}</td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <Pagination
            links={links}
            isLoading={false}
            limit={params.limit}
            setLimit={(limit: number) => setParams({ ...params, limit: limit })}
            setActivePage={page => {
              setParams({ ...params, page: page });
            }}
          />
        </KTCardBody>
      </KTCard>
      {isLoading && <ListLoading />}
    </>
  );
};

export { DataGrid };

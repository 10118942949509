import { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { KTSVG } from '../../../_metronic/helpers';

import { useFormik } from 'formik';
import { usePartnersContext } from '../../../context/context';

import { OfficesForm } from './OfficesForm';

import clsx from 'clsx';

type Props = {
  openModal: any;
  onClose: any;
  partner: any;
};

const editPartnerSchema = Yup.object().shape({
  companyName: Yup.string().required('Numele companiei este obligatoriu!'),
  cui: Yup.string().required('Cui este obligatoriu!'),
});

const PartnerAddEditModal: FC<Props> = ({ openModal, onClose, partner }) => {
  const isEdit = Object.values(partner).length > 0;

  const { addPartner, editPartner, submitErrors, getShops } = usePartnersContext();

  useEffect(() => {
    getShops();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [form, setForm] = useState<any>({
    ...partner,
    companyName: partner.company_name || '',
    cui: partner.cui || '',
    offices: partner.offices || [],
    id: partner ? partner.id : null,
  });

  const formik = useFormik({
    initialValues: form,
    validationSchema: editPartnerSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const offices = form.offices.map((office: any) => ({
        id: office.id,
        name: office.name,
        email: office.email,
        phone: office.phone,
        city: office?.city?.id ? office.city.id : office.city,
        shops: office.shops.map((shop: any) => (shop?.id ? { shop_id: shop.id } : shop)),
      }));

      const data = {
        // ...values,
        company_name: values.companyName,
        cui: values.cui,
        offices: offices,
      };
      let res: any = null;
      if (isEdit) {
        res = await editPartner(partner.id, data);
      } else {
        res = await addPartner(data);
      }

      setSubmitting(false);

      if (res.error) {
        if (res.error.response.data) {
          Object.keys(res.error.response.data).forEach(name => {
            formik.setErrors({ ...formik.errors, [name]: res.error.response.data[name][0] });
          });
        }
      } else {
        onClose && onClose();
      }
    },
  });

  const addNewForm = () => {
    setForm({
      ...form,
      offices: [...form.offices, ...[{ city: null, name: '', email: '', phone: '', shops: [] }]],
    });
  };

  return (
    <>
      {openModal && (
        <>
          <div className='modal fade show d-block' id='kt_modal_add_user' role='dialog' tabIndex={-1} aria-modal='true'>
            <div className='modal-dialog modal-dialog-centered mw-950px'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h2 className='fw-bolder'>{isEdit ? 'Editare Partner' : 'Adaigare Partner'}</h2>
                  <div
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    data-kt-users-modal-action='close'
                    onClick={() => onClose()}
                    style={{ cursor: 'pointer' }}>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                  </div>
                </div>
                <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                  <form
                    onSubmit={formik.handleSubmit}
                    id='kt_modal_add_user_form'
                    className='form d-flex flex-wrap'
                    autoComplete={'off'}>
                    <div className='w-50 px-2 mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Nume companie</label>
                      <input
                        placeholder='Nume companie'
                        {...formik.getFieldProps('companyName')}
                        type='text'
                        name='companyName'
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          { 'is-invalid': formik.touched.companyName && formik.errors.companyName },
                          {
                            'is-valid': formik.touched.companyName && !formik.errors.companyName,
                          }
                        )}
                        autoComplete='off'
                        value={formik.values.companyName}
                        disabled={formik.isSubmitting}
                      />
                      {formik.touched.companyName && formik.errors.companyName && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>
                              {typeof formik.errors.companyName === 'string' && formik.errors.companyName}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className=' w-50 px-2 mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Cui</label>
                      <input
                        placeholder='Cui'
                        {...formik.getFieldProps('cui')}
                        type='text'
                        name='cui'
                        className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          { 'is-invalid': formik.touched.cui && formik.errors.cui },
                          {
                            'is-valid': formik.touched.cui && !formik.errors.cui,
                          }
                        )}
                        autoComplete='off'
                        value={formik.values.cui}
                        disabled={formik.isSubmitting}
                      />
                      {formik.touched.cui && formik.errors.cui && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{typeof formik.errors.cui === 'string' && formik.errors.cui}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='w-100 fv-row mb-7'>
                      <label className='required fw-bold fs-2 mb-2 px-2'>Oficii</label>
                      {form.offices.map((item: any, index: any) => (
                        <div key={`office-${index}`}>
                          <OfficesForm
                            errors={submitErrors}
                            office={item}
                            officeIndex={index}
                            isEdit={isEdit}
                            setOffice={(officeUpdated: object) =>
                              setForm({
                                ...form,
                                offices: [...form.offices.map((q: any, j: any) => (j === index ? officeUpdated : q))],
                              })
                            }
                            removeItem={() =>
                              setForm({
                                ...form,
                                offices: [...form.offices.filter((q: any, j: any) => j !== index)],
                              })
                            }
                          />
                        </div>
                      ))}
                      <div className='text-right'>
                        <button type='button' className='btn btn-outline me-1 ' onClick={() => addNewForm()}>
                          <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-3' />
                          <span className='mx-3'>Adauga oficiu</span>
                        </button>
                      </div>
                      {submitErrors?.[`offices`] && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{submitErrors?.[`offices`]?.join(' ')}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <button type='submit' className='btn btn-sm btn-primary' disabled={formik.isSubmitting}>
                      Salveaza
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
    </>
  );
};

export { PartnerAddEditModal };

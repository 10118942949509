import { FC, Fragment, useState } from 'react';
import * as Yup from 'yup';
import { MultiSelect } from 'react-multi-select-component';

import { useFormik } from 'formik';
import { useUsersContext } from '../../../context/context';

import clsx from 'clsx';

type Props = {
  onClose?: () => void;
  user?: any;
  isEdit: any;
};

const editUserSchema = Yup.object().shape({
  email: Yup.string().email('Introduceti un email valid!').required('Emailul este obligatoriu!'),
  first_name: Yup.string().required('Numele este obligatoriu!'),
  last_name: Yup.string().required('Prenumele este obligatoriu!'),
  password: Yup.string(),
  phone: Yup.string(),
});

const UserModalContent: FC<Props> = ({ user, isEdit, onClose }) => {
  const { addUser, editUser, checkChangeRole, checkUniqueness, roles, permissions, departs } = useUsersContext();

  const [editData] = useState({
    ...user,
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    email: user.email || '',
    password: user.password || '',
    phone: user.phone || '',
    role: user.role || '',
    permissions: user.permissions || [],
    depart: user.depart || [],
  });

  const [selectedRole, setSelectedRole] = useState(editData?.role || 'admin-fm');
  const [selectedDepartment, setSelectedDepartment] = useState(editData.depart || []);
  const [selectedPermissions, setSelectedPermissions] = useState(editData?.permissions || []);
  const formik = useFormik({
    initialValues: editData,
    validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const resCheck = await checkUniqueness({
        model: 'User',
        field: 'email',
        value: values.email,
        id: user ? user.id : null,
      });
      if (resCheck.data) {
        setSubmitting(false);
        formik.setErrors({
          email: 'Acest email este deja folosit.',
        });
        return;
      }

      if (isEdit && user?.role !== (selectedRole || values.role)) {
        const resRole = await checkChangeRole({
          model: 'User',
          field: 'email',
          value: selectedRole || values.role,
          id: user.id,
        });
        if (!resRole.data) {
          setSubmitting(false);
          formik.setErrors({
            role: 'Nu este permisa modificarea rolului unui utilizator care detine o functie. Accesati mai intai meniul "Regiuni", mergeti la campul functiei respective si transferati-o, selectand un alt utilizator. Reveniti apoi si modificati rolul.',
          });
          return;
        }
      }

      const data = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password: values.password ? values.password : undefined,
        phone: values.phone,
        role: selectedRole || values.role,
        depart: selectedDepartment,
        permissions: selectedPermissions,
      };
      let res: any = null;
      if (isEdit) {
        res = await editUser(user.id, data);
      } else {
        res = await addUser(data);
      }

      setSubmitting(false);

      if (res.error) {
        if (res.error.response.data) {
          let errors = {};
          Object.keys(res.error.response.data).forEach(name => {
            errors = {
              ...errors,
              [name]: res.error.response.data[name][0],
            };
          });
          formik.setErrors(errors);
        }
      } else {
        onClose && onClose();
      }
    },
  });

  const onHandleSelectRole = (e: any) => {
    const value = e.target.value;
    if (isEdit && user?.role !== value) {
      checkChangeRole({
        model: 'User',
        field: 'email',
        value: value,
        id: user.id,
      }).then(({ data, error }: any) => {
        if (!data) {
          formik.setErrors({
            role: 'Nu este permisa modificarea rolului unui utilizator care detine o functie. Accesati mai intai meniul "Regiuni", mergeti la campul functiei respective si transferati-o, selectand un alt utilizator. Reveniti apoi si modificati rolul.',
          });
        } else {
          setSelectedDepartment([]);
          setSelectedPermissions([]);
          setSelectedRole(value);
        }
      });
    } else {
      setSelectedDepartment([]);
      setSelectedPermissions([]);
      setSelectedRole(value);
    }
  };

  const isDepartment = selectedRole === 'departments';
  const isMarket = selectedRole === 'manager_zone' || selectedRole === 'manager_shop' || selectedRole === 'operator';

  const departments = Object.keys(departs).map(id => ({
    id,
    name: departs[id],
  }));

  return (
    <>
      <form onSubmit={formik.handleSubmit} id='kt_modal_add_user_form' className='form' autoComplete={'off'}>
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>Nume</label>
          <input
            placeholder='Nume'
            {...formik.getFieldProps('first_name')}
            type='text'
            name='first_name'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.first_name && formik.errors.first_name },
              {
                'is-valid': formik.touched.first_name && !formik.errors.first_name,
              }
            )}
            autoComplete='off'
            value={formik.values.first_name}
            disabled={formik.isSubmitting}
          />
          {formik.touched.first_name && formik.errors.first_name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{typeof formik.errors.first_name === 'string' && formik.errors.first_name}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>Prenume</label>
          <input
            placeholder='Prenume'
            {...formik.getFieldProps('last_name')}
            type='text'
            name='last_name'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.last_name && formik.errors.last_name },
              {
                'is-valid': formik.touched.last_name && !formik.errors.last_name,
              }
            )}
            autoComplete='off'
            value={formik.values.last_name}
            disabled={formik.isSubmitting}
          />
          {formik.touched.last_name && formik.errors.last_name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{typeof formik.errors.last_name === 'string' && formik.errors.last_name}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>Email/Username</label>
          <input
            placeholder='Email/Username'
            {...formik.getFieldProps('email')}
            type='text'
            name='email'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            value={formik.values.email}
            disabled={formik.isSubmitting}
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{typeof formik.errors.email === 'string' && formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-7'>
          <label className={(!isEdit && 'required') + ' fw-bold fs-6 mb-2'}>Parola</label>
          <input
            placeholder='Parola'
            {...formik.getFieldProps('password')}
            type='password'
            name='password'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.password && formik.errors.password },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
            autoComplete='off'
            value={formik.values.password}
            disabled={formik.isSubmitting}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{typeof formik.errors.password === 'string' && formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-7'>
          <label className=' fw-bold fs-6 mb-2'>Telefon</label>
          <input
            placeholder='Telefon'
            {...formik.getFieldProps('phone')}
            type='text'
            name='phone'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.phone && formik.errors.phone },
              {
                'is-valid': formik.touched.phone && !formik.errors.phone,
              }
            )}
            autoComplete='off'
            value={formik.values.phone}
            disabled={formik.isSubmitting}
          />
          {formik.touched.phone && formik.errors.phone && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{typeof formik.errors.phone === 'string' && formik.errors.phone}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>Role</label>
          <select
            placeholder='Role'
            {...formik.getFieldProps('role')}
            name='role'
            data-control='role'
            data-hide-search='true'
            className={clsx(
              'form-control form-control-solid form-select-sm form-select mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.role && formik.errors.role },
              {
                'is-valid': formik.touched.role && !formik.errors.role,
              }
            )}
            onChange={onHandleSelectRole}
            value={selectedRole}>
            {roles?.map((role: { name: string }, index: number) => {
              return (
                <option key={index} value={role.name}>
                  {role.name}
                </option>
              );
            })}
          </select>
          {formik.touched.role && formik.errors.role && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{typeof formik.errors.role === 'string' && formik.errors.role}</span>
              </div>
            </div>
          )}
        </div>
        {isMarket && (
          <Fragment>
            <div className='fv-row mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Acces la Magazine</label>
              <MultiSelect
                hasSelectAll={false}
                options={permissions.map((q: { name: string; label: string }) => ({
                  label: q.label,
                  value: q.name,
                  disabled:
                    selectedRole === 'manager_shop' &&
                    selectedPermissions.length &&
                    !selectedPermissions.find((k: any) => k === q.name),
                }))}
                value={selectedPermissions.map((q: any) => ({ label: q === 'No Shops' ? 'Nealocat' : q, value: q }))}
                onChange={(selected: any) => {
                  if (selected.find((q: any) => q.value === 'No Shops')) {
                    setSelectedPermissions(
                      selected.filter((q: any) => q.value === 'No Shops').map((q: any) => q.value)
                    );
                  } else {
                    setSelectedPermissions(selected.map((q: any) => q.value));
                  }
                }}
                labelledBy='Select'
              />
              {formik.errors.permissions && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>
                      {typeof formik.errors.permissions === 'string' && formik.errors.permissions}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </Fragment>
        )}
        {isDepartment && (
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Departament</label>
            <MultiSelect
              hasSelectAll={false}
              options={departments.map(q => ({
                label: q.name,
                value: q.id,
              }))}
              value={departments
                .filter(q => selectedDepartment.find((selected: any) => selected === q.id))
                .map(q => ({
                  label: q.name,
                  value: q.id,
                }))}
              onChange={(value: any) => setSelectedDepartment(value.map((q: any) => q.value))}
              labelledBy='Select'
            />
          </div>
        )}
        <button type='submit' className='btn btn-sm btn-primary' disabled={formik.isSubmitting}>
          Salveaza
        </button>
      </form>
    </>
  );
};

export { UserModalContent };

import { FC } from 'react';
import { KTSVG } from '../../../_metronic/helpers';

type Props = {
  onClose: any;
  isEdit: any;
};

const UserModalHeader: FC<Props> = ({ onClose, isEdit }) => {
  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>{isEdit ? 'Editare utilizator' : 'Adaugare utilizator'}</h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => onClose()}
        style={{ cursor: 'pointer' }}>
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
    </div>
  );
};

export { UserModalHeader };

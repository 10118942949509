import { FC, useState, useEffect } from 'react';
import Select from 'react-select';
import * as Yup from 'yup';
import { KTSVG } from '../../../_metronic/helpers';
import { useFormik } from 'formik';
import { useMaterialsContext } from '../../../context/context';
import { useSearchParams } from 'react-router-dom';
import clsx from 'clsx';

type Props = {
  openModal: any;
  onClose: any;
  material: any;
};

const editMaterialSchema = Yup.object().shape({
  name: Yup.string().required('Numele este obligatoriu!'),
  brand: Yup.string().required('Marca este obligatorie!'),
  description: Yup.string().required('Descrierea este obligatorie!'),
});

const MaterialAddEditModal: FC<Props> = ({ openModal, onClose, material }) => {
  const isEdit = Object.values(material).length > 0;
  const [searchParams] = useSearchParams();
  const [firstLevelOptions, setFirstLevelOptions] = useState([]);
  const [secondFilterOptions, setSecondFilterOptions] = useState([]);
  const [thirdFilterOptions, setThirdFilterOptions] = useState([]);
  const [filterParams] = useState({
    parent_id: searchParams.get('parent_id'),
  });
  const [familiesItem, setFamiliesItem] = useState<any>(null);
  const [subFamiliesItem, setSubFamiliesItem] = useState<any>(null);
  const [kauflandCodesItem, setKauflandCodesItem] = useState<any>(null);
  const [errors, setErrors] = useState<any>({});

  const { addMaterial, editMaterial, getCategoryMaterialsForFilters, getCategoryMaterialDetalisForFilters } =
    useMaterialsContext();

  const [editData] = useState({
    ...material,
    code: material.code || undefined,
    name: material.name || '',
    brand: material.brand || '',
    description: material.description || '',
    id: material ? material.id : undefined,
  });

  const formik = useFormik({
    initialValues: editData,
    validationSchema: editMaterialSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setErrors({});

      const data = {
        material_category_id: kauflandCodesItem?.value,
        name: values.name,
        code: values.code,
        brand: values.brand,
        description: values.description,
      };

      let res: any = null;
      if (isEdit) {
        res = await editMaterial(material.id, data);
      } else {
        res = await addMaterial(data);
      }

      setSubmitting(false);

      if (res.error) {
        if (res.error.response.data) {
          Object.keys(res.error.response.data).forEach(name => {
            formik.setErrors({
              ...formik.errors,
              [name]: res.error.response.data[name][0],
            });
          });
          setErrors(res.error.response.data);
        }
      } else {
        onClose && onClose();
      }
    },
  });

  const getFamilyOptions = () => {
    return firstLevelOptions.map((item: any) => ({ label: item.name, value: item.id }));
  };

  const getSubFamilyOptions = () => {
    return secondFilterOptions.map((item: any) => ({ label: item.name, value: item.id }));
  };

  const getCodKauflandOptions = () => {
    return thirdFilterOptions.map((item: any) => ({ label: item.name, value: item.id }));
  };

  const onChangeFamilySelect = (e: any) => {
    setFamiliesItem(getFamilyOptions().find((item: any) => item.value === parseInt(e.value)));

    getCategoryMaterialDetalisForFilters(e.value).then((res: any) => {
      setSecondFilterOptions(res?.data?.childs ?? []);
    });

    setSubFamiliesItem(null);
    setKauflandCodesItem(null);
  };

  const onChangeSubFamilySelect = (e: any) => {
    setSubFamiliesItem(getSubFamilyOptions().find((item: any) => item.value === parseInt(e.value)));

    getCategoryMaterialDetalisForFilters(e.value).then((res: any) => {
      setThirdFilterOptions(res.data?.childs ?? []);
    });

    setKauflandCodesItem(null);
  };

  const onChangeKauflandCodesSelect = (e: any) => {
    setKauflandCodesItem(getCodKauflandOptions().find((item: any) => item.value === parseInt(e.value)));
  };

  useEffect(() => {
    getCategoryMaterialsForFilters({ level: 1 }).then((res: any) => {
      setFirstLevelOptions(res.data.data);
    });

    if (filterParams.parent_id && filterParams.parent_id !== undefined) {
      getCategoryMaterialDetalisForFilters(filterParams.parent_id).then((res: any) => {
        setSecondFilterOptions(res.data?.parent?.parent?.childs ?? []);
        setThirdFilterOptions(res.data?.parent?.childs ?? []);

        if (isEdit) {
          setFamiliesItem({
            label: res.data?.parent?.parent?.name,
            value: res.data?.parent?.parent?.id,
          });

          setSubFamiliesItem({
            label: res.data?.parent?.name,
            value: res.data?.parent?.id,
          });

          setKauflandCodesItem({
            label: res.data?.name,
            value: res.data?.id,
          });
        }
      });
    } else {
      if (isEdit) {
        getCategoryMaterialDetalisForFilters(material.material_category_id).then((res: any) => {
          setSecondFilterOptions(res.data?.parent?.parent?.childs ?? []);
          setThirdFilterOptions(res.data?.parent?.childs ?? []);

          setFamiliesItem({
            label: res.data?.parent?.parent?.name,
            value: res.data?.parent?.parent?.id,
          });

          setSubFamiliesItem({
            label: res.data?.parent?.name,
            value: res.data?.parent?.id,
          });

          setKauflandCodesItem({
            label: res.data?.name,
            value: res.data?.id,
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className='modal fade show d-block' id='kt_modal_add_user' role='dialog' tabIndex={-1} aria-modal='true'>
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>{isEdit ? 'Edit Cod sub-articol' : 'Add Cod sub-articol'}</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => onClose()}
                style={{ cursor: 'pointer' }}>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <form onSubmit={formik.handleSubmit} id='kt_modal_add_user_form' className='form' autoComplete={'off'}>
                <div className='fv-row mb-7'>
                  <label className=' fw-bold fs-6 mb-2'>Code</label>
                  <input
                    placeholder='Code'
                    {...formik.getFieldProps('code')}
                    type='text'
                    name='code'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.code && formik.errors.code },
                      {
                        'is-valid': formik.touched.name && !formik.errors.name,
                      }
                    )}
                    autoComplete='off'
                    value={formik?.values?.code}
                    disabled={formik.isSubmitting}
                  />
                  {formik.touched.code && formik.errors.code && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{typeof formik.errors.code === 'string' && formik.errors.code}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Nume</label>
                  <input
                    placeholder='Nume'
                    {...formik.getFieldProps('name')}
                    type='text'
                    name='name'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.name && formik.errors.name },
                      {
                        'is-valid': formik.touched.name && !formik.errors.name,
                      }
                    )}
                    autoComplete='off'
                    value={formik?.values?.name}
                    disabled={formik.isSubmitting}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{typeof formik.errors.name === 'string' && formik.errors.name}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Brand</label>
                  <input
                    placeholder='Brand'
                    {...formik.getFieldProps('brand')}
                    type='text'
                    name='brand'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.brand && formik.errors.brand },
                      {
                        'is-valid': formik.touched.brand && !formik.errors.brand,
                      }
                    )}
                    autoComplete='off'
                    value={formik?.values?.brand}
                    disabled={formik.isSubmitting}
                  />
                  {formik.touched.brand && formik.errors.brand && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{typeof formik.errors.brand === 'string' && formik.errors.brand}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Descriere</label>
                  <textarea
                    placeholder='Descriere'
                    {...formik.getFieldProps('description')}
                    name='description'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.description && formik.errors.description },
                      {
                        'is-valid': formik.touched.description && !formik.errors.description,
                      }
                    )}
                    autoComplete='off'
                    value={formik?.values?.description}
                    disabled={formik.isSubmitting}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>
                          {typeof formik.errors.description === 'string' && formik.errors.description}
                        </span>
                      </div>
                    </div>
                  )}
                </div>

                <>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Familie</label>
                    <Select
                      onChange={onChangeFamilySelect}
                      options={getFamilyOptions()}
                      placeholder='Familie'
                      name='familii'
                      value={familiesItem}
                      className='form-control-async-select form-control-solid form-select-solid'
                    />
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Sub-Familie</label>
                    <Select
                      onChange={onChangeSubFamilySelect}
                      options={getSubFamilyOptions()}
                      placeholder='Sub-Familie'
                      name='sub-familii'
                      value={subFamiliesItem}
                      className='form-control-async-select form-control-solid form-select-solid'
                    />
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Cod Kaulfand</label>
                    <Select
                      onChange={onChangeKauflandCodesSelect}
                      options={getCodKauflandOptions()}
                      placeholder='Cod Kaulfand'
                      name='coduri-kaufland'
                      className='form-control-async-select form-control-solid form-select-solid'
                      value={kauflandCodesItem}
                    />
                    {errors?.material_category_id?.[0] && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors?.material_category_id?.[0]}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </>

                <button type='submit' className='btn btn-sm btn-primary' disabled={formik.isSubmitting}>
                  Salveaza
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  );
};

export { MaterialAddEditModal };

import { useState } from 'react';
import { DataGrid } from '../dataGrid/DataGrid';
import { CustomHeader } from '../dataGrid/CustomHeader';
import { Column } from 'react-table';
import { PartnerAddEditModal } from './PartnersAddEditModal';
import { User } from '../apps/user-management/users-list/core/_models';
import { usePartnersContext } from '../../../context/context';
import { KTSVG } from '../../../_metronic/helpers';
import Swal from 'sweetalert2';

const PartnersTable = () => {
  const { gridData, deletePartner, getPartner } = usePartnersContext();
  const { params, setParams, getData } = gridData;
  const [openModal, setOpenModal] = useState(false);
  const [partnerData, setPartnerData] = useState({});

  const ReportsColumns: ReadonlyArray<Column<User>> = [
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Nr' className='min-w-50px' params={params} setParams={setParams} />
      ),
      id: 'id',
      Cell: ({ ...props }) => props.data[props.row.index].id,
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Nume' className='min-w-150px' params={params} setParams={setParams} />
      ),
      id: 'company_name',
      Cell: ({ ...props }) => props.data[props.row.index].company_name,
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='CUI' className='min-w-150px' params={params} setParams={setParams} />
      ),
      id: 'cui',
      Cell: ({ ...props }) => props.data[props.row.index].cui,
    },
    // {
    //   Header: props => (
    //     <CustomHeader tableProps={props} title='Email' className='min-w-150px' params={params} setParams={setParams} />
    //   ),
    //   id: 'email',
    //   Cell: ({ ...props }) => props.data[props.row.index].email,
    // },
    {
      Header: props => <CustomHeader tableProps={props} title='Actiuni' className='text-end min-w-100px' />,
      id: 'actions',
      Cell: ({ ...props }) => {
        return (
          <div>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => onOpenEdit(props.row.original)}>
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </button>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              onClick={() =>
                Swal.fire({
                  icon: 'error',
                  title: 'Esti sigur?',
                  showCancelButton: true,
                  confirmButtonText: 'Sterge',
                  cancelButtonText: 'Anulare',
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                }).then(result => {
                  Swal.showLoading();
                  if (result.isConfirmed) {
                    deletePartner(props.row.original.id).then(() => {
                      getData(params);
                    });
                  }
                })
              }>
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
            </button>
          </div>
        );
      },
    },
  ];

  const onCloseModal = () => {
    getData(params);
    setOpenModal(false);
    setPartnerData({});
  };

  const onOpenEdit = async (values: { id: any }) => {
    const partner = await getPartner(values?.id);
    setPartnerData(partner.data);
    setOpenModal(true);
  };

  return (
    <div>
      {openModal && <PartnerAddEditModal openModal={openModal} onClose={onCloseModal} partner={partnerData} />}
      <DataGrid
        columns={ReportsColumns}
        useDataContext={usePartnersContext}
        addButton={{
          label: 'Adauga Partner',
          action: () => setOpenModal(true),
        }}
      />
    </div>
  );
};

export { PartnersTable };

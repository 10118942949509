import { FC, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { KTSVG } from '../../../_metronic/helpers';

import { useFormik } from 'formik';
import {
  usePartnersContext,
  useOffersContext,
  useMeasurementUnitsContext,
  useCurrencyContext,
} from '../../../context/context';
import Select from 'react-select';
import { MultiSelect } from 'react-multi-select-component';

import { SubArticleForm } from './SubArticleForm';

type Props = {
  openModal: any;
  onClose: any;
  offer: any;
};

const editDealSchema = Yup.object().shape({
  shops: Yup.array().required('Magazul este obligatoriu!'),
});

const DealAddEditModal: FC<Props> = ({ openModal, onClose, offer }) => {
  const isEdit = Object.values(offer).length > 0;

  const { getMeasurementUnits } = useMeasurementUnitsContext();
  const { getPartners, partners } = usePartnersContext();
  const { getCurrency } = useCurrencyContext();
  const { addOffer, editOffer, submitErrors } = useOffersContext();
  const [selectedPartner, setSelectedPartner] = useState<any>({ partner: null, offices: [] });
  const [selectedOffice, setSelectedOffice] = useState<any>({ office: null, shops: [] });
  const [selectedShops, setSelectedShops] = useState([]);
  const [loading, setLoading] = useState(true);

  // const [partners, setPartners] = useState([]);

  const [form, setForm] = useState<any>({
    ...offer,
    partner: offer.partner_id || null,
    office: offer.partner_office_id || null,
    shops: offer.shops || [],
    products: offer.products || [],
    id: offer ? offer.id : null,
  });

  useEffect(() => {
    fetchData();

    if (isEdit) {
      onHandleSelectPartner(null, form.partner);
      onHandleSelectOffice(null, form.office);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEdit) {
      editData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partners]);

  const fetchData = async () => {
    await getMeasurementUnits();
    await getPartners({ limit: 1000 });
    if (offer?.products?.length) {
      await getCurrency({ date: offer?.products[0].date });
    }
    setLoading(false);
  };

  const editData = async () => {
    onHandleSelectPartner(null, form.partner);
  };

  const formik = useFormik({
    initialValues: form,
    validationSchema: editDealSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const products = form.products.map((product: any) => ({
        material_id: product?.id ? product.material_id : product.material_id?.value,
        unit_id: product?.id ? product.unit_id : product.unit_id?.value,
        currency_id: product?.currency_id?.value ? product.currency_id?.value : product.currency_id,
        date: product.date,
        price: product.price,
        status: product.status?.value ? product.status?.value : product.status,
        id: product.id,
      }));

      const data = {
        // ...values,
        partner_id: selectedPartner?.partner?.value || values.partner_id,
        partner_office_id: selectedOffice?.office?.value || values.partner_office_id,
        shops: selectedShops.length > 0 ? selectedShops : values.shops.map((q: any) => q.id),
        products: products || [],
      };

      let res: any = null;
      if (isEdit) {
        res = await editOffer(offer.id, data);
      } else {
        res = await addOffer(data);
      }

      setSubmitting(false);

      if (res.error) {
        if (res.error.response.data) {
          Object.keys(res.error.response.data).forEach(name => {
            formik.setErrors({ ...formik.errors, [name]: res.error.response.data[name][0] });
          });
        }
      } else {
        onClose && onClose();
      }
    },
  });

  const onHandleSelectPartner = (partner: any, editData: any) => {
    partners?.data?.forEach((item: any) => {
      if (!editData ? item.id === partner.value : item.id === editData) {
        const newPartner = !editData ? partner : { label: item.company_name, value: item.id };
        return setSelectedPartner({ ...selectedPartner, partner: newPartner, offices: item?.offices });
      }
    });
    setSelectedOffice({ office: null, shops: [] });
    setSelectedShops([]);
  };
  const onHandleSelectOffice = (office: any, editData: any) => {
    selectedPartner?.offices?.forEach((item: any) => {
      if (!editData ? item.id === office.value : item.id === editData) {
        const newOffice = !editData ? office : { label: item.name + ' - ' + item.city?.county, value: item.id };
        return setSelectedOffice({ ...selectedOffice, office: newOffice, shops: item?.shops });
      }
    });
    setSelectedShops([]);
  };

  const onChangeMultiSelect = (value: any, editData: any) => {
    const shops = editData ? editData.map((q: any) => q.id) : value.map((q: any) => q.value);
    setSelectedShops(shops);
  };

  const addNewForm = () => {
    setForm({
      ...form,
      products: [
        ...form.products,
        ...[{ material_id: null, unit_id: null, currency_id: null, date: '', price: null, status: '' }],
      ],
    });
  };

  const onDeleteSubArticle = (value: any) => {
    const newSubArticles = form.products.filter((item: any, index: any) => index !== value);
    setForm({
      ...form,
      products: newSubArticles,
    });
  };

  const defaultOffice =
    isEdit && offer?.office
      ? {
          label: offer?.office.name + (offer?.office?.city ? ' - ' + offer?.office?.city.county : ''),
          value: offer?.office.id,
        }
      : undefined;
  const defaultShops =
    isEdit && offer?.office
      ? offer?.office?.shops
          .filter((shop: any) => offer.shops.find((item: any) => item.id === shop.id))
          .map((q: any) => ({ label: q.name + (q.city ? ' - ' + q.city.county : ''), value: q.id }))
      : undefined;

  const selectedShopsValues = offer?.id
    ? defaultShops
    : selectedOffice?.shops
        ?.filter((q: any) => selectedShops?.find((selected: any) => selected === q.id))
        .map((q: any) => ({
          label: q.name + (q.city ? ' - ' + q.city.county : ''),
          value: q.id,
        }));

  if (loading) return <></>;

  return (
    <>
      {openModal && (
        <>
          <div
            className='modal fade show d-block'
            id='kt_modal_add_offer'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'>
            <div className='modal-dialog modal-dialog-centered ' style={{ maxWidth: '90vw', width: '90vw' }}>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h2 className='fw-bolder'>{isEdit ? 'Editare oferta' : 'Adaugare oferta'}</h2>
                  <div
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    data-kt-users-modal-action='close'
                    onClick={() => onClose()}
                    style={{ cursor: 'pointer' }}>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                  </div>
                </div>
                <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                  <form
                    onSubmit={formik.handleSubmit}
                    id='kt_modal_add_user_form'
                    className='form d-flex flex-wrap w-100'
                    autoComplete={'off'}>
                    <div className='w-50 px-2 mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Partener</label>
                      <Select
                        onChange={e => onHandleSelectPartner(e, null)}
                        options={partners?.data?.map((q: any) => ({ label: q.company_name, value: q.id }))}
                        placeholder='Partener'
                        name='partner'
                        isDisabled={isEdit}
                        value={selectedPartner.partner ? selectedPartner?.partner : null}
                        className='form-control-async-select'
                      />
                      {submitErrors?.partner_id && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{submitErrors?.partner_id.join(' ')}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className=' w-50 px-2 mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Punct de lucru</label>
                      <Select
                        onChange={e => onHandleSelectOffice(e, null)}
                        options={selectedPartner?.offices?.map((office: any) => ({
                          label: office.name + (office.city ? ' - ' + office.city.county : ''),
                          value: office.id,
                        }))}
                        isDisabled={isEdit}
                        placeholder='Punct de lucru'
                        name='office'
                        value={selectedOffice.office ? selectedOffice.office : defaultOffice}
                        className='form-control-async-select'
                      />
                      {submitErrors?.partner_office_id && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{submitErrors?.partner_office_id.join(' ')}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className=' w-50 px-2 mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Magazine</label>
                      <MultiSelect
                        hasSelectAll={false}
                        options={[
                          ...selectedShopsValues,
                          ...selectedOffice?.shops
                            ?.filter(
                              (q: any) => !selectedShopsValues.find((selected: any) => +selected.value === +q.id)
                            )
                            ?.map((q: any) => ({
                              label: q.name + (q.city ? ' - ' + q.city.county : ''),
                              value: q.id,
                            })),
                        ]}
                        value={selectedShopsValues}
                        disabled={isEdit}
                        onChange={(e: any) => onChangeMultiSelect(e, null)}
                        labelledBy='Select'
                        dropdown-heading
                        className='form-control-async-select-multiple'
                      />
                      {submitErrors?.shops && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{submitErrors?.shops.join(' ')}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='w-100 fv-row mb-7'>
                      <label className='required fw-bold fs-2 mb-2 px-2'>Produse</label>
                      {form.products.map((item: any, index: any) => (
                        <div key={`office-${index}`}>
                          <SubArticleForm
                            errors={submitErrors}
                            product={item}
                            subArticleIndex={index}
                            isEdit={isEdit}
                            onDeleteSubArticle={onDeleteSubArticle}
                            setSubArticle={(productsUpdated: object) =>
                              setForm({
                                ...form,
                                products: [
                                  ...form.products.map((q: any, j: any) => (j === index ? productsUpdated : q)),
                                ],
                              })
                            }
                          />
                        </div>
                      ))}
                      <div className='text-right d-flex justify-content-between'>
                        <button type='button' className='btn btn-outline me-1 ' onClick={() => addNewForm()}>
                          <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-3' />
                          <span className='mx-3'>Adauga Sub-Article</span>
                        </button>
                        <a target='_blank' className='btn btn-primary me-1' href='/materials'>
                          <span className='mx-3'>Adauga material nou</span>
                        </a>
                      </div>
                    </div>
                    <button type='submit' className='btn btn-sm btn-primary' disabled={formik.isSubmitting}>
                      Salveaza
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
    </>
  );
};

export { DealAddEditModal };

import React, { useState } from 'react';
import { api } from '../api';

export const OffersContext = React.createContext();

export const OffersProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [submitErrors, setSubmitErrors] = useState({});
  const [gridParams, setGridParams] = useState({
    page: 1,
    limit: 10,
  });
  const [offers, setOffers] = useState({
    data: [],
  });
  const [offer, setOffer] = useState({
    data: [],
  });

  const getOffers = async query => {
    setLoading(true);
    return await api
      .get('/api/offer', {
        params: query,
      })
      .then(response => {
        setOffers(response.data);
        setLoading(false);
        return response.data;
      })
      .catch(error => {
        setLoading(false);
        return { error };
      });
  };

  const getOffer = async id => {
    setSubmitErrors({});
    setLoading(true);
    return await api
      .get(`/api/offer/${id}`)
      .then(response => {
        setOffer(response.data);
        setLoading(false);
        return { data: response.data };
      })
      .catch(error => {
        setLoading(false);
        return { error };
      });
  };

  const addOffer = async data => {
    setSubmitErrors({});
    return await api
      .post('/api/offer', data)
      .then(response => ({ data: response.data }))
      .catch(error => {
        setSubmitErrors(error?.response?.data || {});
        return { error };
      });
  };

  const editOffer = async (id, data) => {
    setSubmitErrors({});
    return await api
      .put(`/api/offer/${id}`, data)
      .then(response => response.data)
      .catch(error => {
        setSubmitErrors(error?.response?.data || {});
        return { error };
      });
  };

  const deleteOffer = async id => {
    return await api
      .delete(`/api/offer/${id}`)
      .then(response => ({ data: response.data }))
      .catch(error => ({ error }));
  };

  return (
    <OffersContext.Provider
      value={{
        offers,
        offer,
        getOffers,
        getOffer,
        addOffer,
        editOffer,
        deleteOffer,
        submitErrors,
        gridData: {
          getData: getOffers,
          isLoading: loading,
          data: offers?.data || [],
          links: offers?.links || [],
          params: gridParams,
          setParams: setGridParams,
        },
      }}>
      {children}
    </OffersContext.Provider>
  );
};

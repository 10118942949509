import { SetStateAction, useState } from 'react';
import { DataGrid } from '../dataGrid/DataGrid';
import { CustomHeader } from '../dataGrid/CustomHeader';
import { Column } from 'react-table';
import { ShopsAddEditModal } from './ShopsAddEditModal';
import { User } from '../apps/user-management/users-list/core/_models';
import { useShopsContext } from '../../../context/context';
import { KTSVG } from '../../../_metronic/helpers';

const ShopsTable = () => {
  const { gridData } = useShopsContext();
  const { params, setParams, getData } = gridData;
  const [openModal, setOpenModal] = useState(false);
  const [shopsData, setShopsData] = useState({});

  const ShopsColumns: ReadonlyArray<Column<User>> = [
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Regiune' className='min-w-50px' params={params} setParams={setParams} />
      ),
      id: 'city.region.name',
      Cell: ({ ...props }) => props.data[props.row.index].city?.region?.name ?? props.data[props.row.index]?.city?.name,
    },
    {
      Header: props => (
        <CustomHeader
          tableProps={props}
          title='Centru de cost'
          className='min-w-150px'
          params={params}
          setParams={setParams}
        />
      ),
      id: 'name',
      Cell: ({ ...props }) => props.data[props.row.index].name,
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Oras' className='min-w-150px' params={params} setParams={setParams} />
      ),
      id: 'city.name',
      Cell: ({ ...props }) => props.data[props.row.index].city.name,
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Adresa' className='min-w-150px' params={params} setParams={setParams} />
      ),
      id: 'address',
      Cell: ({ ...props }) => props.data[props.row.index].address,
    },
    {
      Header: props => (
        <CustomHeader
          tableProps={props}
          title='Data deschiderii'
          className='min-w-150px'
          params={params}
          setParams={setParams}
        />
      ),
      id: 'open_date',
      Cell: ({ ...props }) => props.data[props.row.index].open_date,
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Tip' className='min-w-120px' params={params} setParams={setParams} />
      ),
      id: 'type',
      Cell: ({ ...props }) => props.data[props.row.index].type,
    },
    {
      Header: props => <CustomHeader tableProps={props} title='Actiuni' className='text-end min-w-100px' />,
      id: 'actions',
      Cell: ({ ...props }) => {
        return (
          <div>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => onOpenEdit(props.row.original)}>
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </button>
          </div>
        );
      },
    },
  ];

  const onCloseModal = () => {
    getData(params);
    setOpenModal(false);
    setShopsData({});
  };

  const onOpenEdit = (values: SetStateAction<{}>) => {
    setShopsData(values);
    setOpenModal(true);
  };

  // const onHandleFilter = (data: object) => {
  //   setParams({ ...params, ...data, page: 1 });
  // };

  return (
    <div>
      {openModal && <ShopsAddEditModal openModal={openModal} onClose={onCloseModal} shop={shopsData} />}
      <DataGrid
        columns={ShopsColumns}
        useDataContext={useShopsContext}
        addButton={{
          label: 'Adauga magazin',
          action: () => setOpenModal(true),
        }}
      />
    </div>
  );
};

export { ShopsTable };

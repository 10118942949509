import { FC, useEffect, useState } from 'react';
import { MenuComponent } from '../../../_metronic/assets/ts/components';
import { KTSVG } from '../../../_metronic/helpers';
import { MultiSelect } from 'react-multi-select-component';
import moment from 'moment';

import { useCurrencyContext, useMeasurementUnitsContext, useShopsContext } from '../../../context/context';

import Select from 'react-select';
import DatePicker from 'react-datepicker';

type Props = {
  onFilterReports: any;
  params?: any;
  currencies: any;
};

const StatusOptions = [
  {
    label: 'Offer',
    value: 'OFFER',
  },
  {
    label: 'Contract',
    value: 'CONTRACT',
  },
  {
    label: 'Expired Contract',
    value: 'EXPIRED_CONTRACT',
  },
  {
    label: 'Terminated Contract',
    value: 'TERMINATED_CONTRACT',
  },
];

const Filter: FC<Props> = ({ onFilterReports, params, currencies }) => {
  const { getCurrency, currency } = useCurrencyContext();
  const { getShops, shops } = useShopsContext();
  const { getMeasurementUnits, measurementUnits } = useMeasurementUnitsContext();

  const [filterParams, setFilterParams] = useState({
    selectedShops: params?.shops || [],
    currencyDate: params?.currencyDate || '',
    selectedProducts: params?.selectedProducts || [],
    selectedMesurementUnits: params?.selectedMesurementUnits || [],
    priceFrom: params?.priceFrom || null,
    priceTo: params?.priceTo || null,
    dateFrom: params?.dateFrom || null,
    dateTo: params?.dateTo || null,
    selectedCurrency: params?.currency || '',
    status: params?.status || null,
  });

  const {
    selectedShops,
    // selectedProducts,
    selectedMesurementUnits,
    priceFrom,
    dateFrom,
    dateTo,
    status,
    priceTo,
    selectedCurrency,
    currencyDate,
  } = filterParams;

  useEffect(() => {
    MenuComponent.reinitialization();
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    await getMeasurementUnits();
    await getShops({ limit: 10000 });
  };

  const resetData = () => {
    const initialParams = {
      selectedShops: null,
      currencyDate: null,
      selectedProducts: null,
      selectedMesurementUnits: null,
      priceFrom: null,
      priceTo: null,
      dateFrom: null,
      dateTo: null,
      selectedCurrency: null,
      status: null,
    };
    const dateFrom: any = document.getElementById('dateFrom');
    const dateTo: any = document.getElementById('dateTo');
    dateFrom.value = null;
    dateTo.value = null;

    setFilterParams(initialParams);
    onFilterReports({ ...initialParams, currencyDate: moment('2022-10-26').format('YYYY-MM-DD'), currency: 'EUR' });
  };

  const filterData = () => {
    onFilterReports(filterParams);
  };

  const onChangeFilterParams = (e: any) => {
    setFilterParams({ ...filterParams, [e.target.name]: e.target.value });
  };

  const onChangeDate = async (value: any) => {
    const date = moment(value).format('YYYY-MM-DD');
    setFilterParams({ ...filterParams, currencyDate: date });
    await getCurrency({ date: date });
  };

  const onHandleSelect = (item: any, target: any) => {
    setFilterParams({ ...filterParams, [target.name]: item });
  };
  return (
    <>
      <button
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'>
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filtre
      </button>
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-700px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filtre</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5 d-flex flex-wrap' data-kt-user-table-filter='form'>
          <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>Data</label>
            <DatePicker
              onChange={onChangeDate}
              selected={currencyDate ? new Date(currencyDate) : null}
              placeholderText='Data'
              dateFormat='dd/MM/yyyy'
              maxDate={new Date()}
              className='form-control-date-piker'
              data-control='currency'
              data-hide-search='true'
            />
          </div>
          <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>Valuta</label>
            <Select
              onChange={onHandleSelect}
              options={currency?.data?.map((q: any) => ({ label: q.symbol, value: q.id }))}
              placeholder='Valuta'
              name='selectedCurrency'
              value={selectedCurrency}
              className='form-control-async-select'
              data-control='currency'
              data-hide-search='true'
            />
          </div>
          <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>Magazine</label>
            <MultiSelect
              hasSelectAll={false}
              options={shops?.data?.map((q: any) => ({
                label: q.name,
                value: q.id,
              }))}
              value={shops?.data
                ?.filter((q: any) => selectedShops?.find((selected: any) => selected === q.id))
                .map((q: any) => ({
                  label: q.name,
                  value: q.id,
                }))}
              onChange={(value: any) =>
                setFilterParams({ ...filterParams, selectedShops: value.map((q: any) => q.value) })
              }
              labelledBy='Select'
              className='form-control-async-select-multiple'
            />
          </div>
          <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>Unitate de masura</label>
            <MultiSelect
              hasSelectAll={false}
              options={measurementUnits?.map((q: any) => ({
                label: q.name,
                value: q.id,
              }))}
              value={measurementUnits
                ?.filter((q: any) => selectedMesurementUnits?.find((selected: any) => selected === q.id))
                .map((q: any) => ({
                  label: q.name,
                  value: q.id,
                }))}
              onChange={(value: any) =>
                setFilterParams({ ...filterParams, selectedMesurementUnits: value.map((q: any) => q.value) })
              }
              labelledBy='Select'
              className='form-control-async-select-multiple'
            />
          </div>

          <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>De la data</label>
            <input
              id='dateFrom'
              className='form-control form-control-solid mb-3 mb-lg-0'
              type='date'
              lang='en'
              name='dateFrom'
              onChange={onChangeFilterParams}
              value={dateFrom}
            />
          </div>
          <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>Pina la data</label>
            <input
              id='dateTo'
              className='form-control form-control-solid mb-3 mb-lg-0 date'
              type='date'
              lang='en'
              name='dateTo'
              onChange={onChangeFilterParams}
              defaultValue={dateTo}
            />
          </div>

          <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>De la pretul</label>
            <input
              placeholder='De la pretul'
              type='text'
              name='priceFrom'
              className='form-control form-control-solid mb-3 mb-lg-0'
              value={priceFrom}
              onChange={onChangeFilterParams}
            />
          </div>
          <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>Pina la pretul</label>
            <input
              placeholder='Pina la pretul'
              type='text'
              name='priceTo'
              className='form-control form-control-solid mb-3 mb-lg-0'
              value={priceTo}
              onChange={onChangeFilterParams}
            />
          </div>
          <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>Statut</label>
            <Select
              onChange={onHandleSelect}
              placeholder='Statut'
              name='status'
              options={StatusOptions?.map((q: any) => ({ label: q.label, value: q.value }))}
              value={status}
              className='form-control-async-select'
            />
          </div>
          {/* <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>Currency</label>
            <select
              placeholder='Currency'
              name='currency'
              data-control='currency'
              data-hide-search='true'
              className='form-control form-control-solid form-select-sm form-select mb-3 mb-lg-0'
              onChange={onSlelectCurrency}
              value={currency}>
              <option value=''></option>
              {currencies?.map((item: { name: string; id: number }, index: number) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div> */}
          <div className='d-flex align-items-center '>
            <button
              type='button'
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'>
              Reseteaza
            </button>
            <button
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'>
              Aplica
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export { Filter };

import React, { useState } from 'react';
import { api } from '../api';

export const PartnersContext = React.createContext();

export const PartnersProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [submitErrors, setSubmitErrors] = useState({});
  const [gridParams, setGridParams] = useState({
    page: 1,
    limit: 10,
  });
  const [partners, setPartners] = useState({
    data: [],
  });
  const [partnerShops, setPartnerShops] = useState({
    data: [],
  });
  const [shops, setShops] = useState([]);
  const [partner, setPartner] = useState({
    data: [],
  });

  const addPartner = async data => {
    return await api
      .post('/api/partner', data)
      .then(response => ({ data: response.data }))
      .catch(error => {
        setSubmitErrors(error?.response?.data || {});
        return { error };
      });
  };

  const editPartner = async (id, data) => {
    setSubmitErrors({});
    return await api
      .put(`/api/partner/${id}`, data)
      .then(response => ({ data: response.data }))
      .catch(error => {
        setSubmitErrors(error?.response?.data || {});
        return { error };
      });
  };

  const getShops = async (id, data) => {
    return await api
      .get(`/api/shop-list`, data)
      .then(response => {
        setShops(response.data);
        return { data: response.data };
      })
      .catch(error => ({ error }));
  };

  const getPartenerShops = async (id, data) => {
    return await api
      .get(`/api/region/${id}/shops`, data)
      .then(response => {
        setPartnerShops(response);
        setLoading(false);
        return { data: response.data };
      })
      .catch(error => {
        return { error };
      });
  };

  const deletePartner = async id => {
    return await api
      .delete(`/api/partner/${id}`)
      .then(response => ({ data: response.data }))
      .catch(error => ({ error }));
  };

  const getPartners = async query => {
    setLoading(true);
    return await api
      .get('/api/partner', {
        params: query,
      })
      .then(response => {
        setPartners(response.data);
        setLoading(false);
        return response.data;
      })
      .catch(error => {
        setLoading(false);
        return { error };
      });
  };

  const getPartner = async id => {
    setSubmitErrors({});
    setLoading(true);
    return await api
      .get(`/api/partner/${id}`)
      .then(response => {
        setPartner(response.data);
        setLoading(false);
        return { data: response.data };
      })
      .catch(error => {
        setLoading(false);
        return { error };
      });
  };

  return (
    <PartnersContext.Provider
      value={{
        partners,
        partnerShops,
        shops,
        partner,
        getPartners,
        getPartner,
        getPartenerShops,
        getShops,
        addPartner,
        deletePartner,
        editPartner,
        submitErrors,
        gridData: {
          getData: getPartners,
          isLoading: loading,
          data: partners?.data || [],
          links: partners?.links || [],
          params: gridParams,
          setParams: setGridParams,
        },
      }}>
      {children}
    </PartnersContext.Provider>
  );
};

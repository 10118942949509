import { FC, useState } from 'react';

type Props = {
  id: any;
  allChecked: any;
  onCheckReport: any;
  checked?: boolean;
};

const GridSelectionCell: FC<Props> = ({ id, allChecked, onCheckReport, checked }) => {
  const [isChecked, setIsChecked] = useState(checked);

  const onHandleCheck = (e: any) => {
    onCheckReport(id, e.target.checked);
    setIsChecked(e.target.checked);
  };

  return (
    <div className='form-check form-check-custom form-check-solid'>
      <input className='form-check-input' type='checkbox' checked={isChecked} onChange={e => onHandleCheck(e)} />
    </div>
  );
};

export { GridSelectionCell };

import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { api } from '../api';
import moment from 'moment';

export const ReportsContext = React.createContext();
const API_URL = process.env.REACT_APP_API_URL;

export const ReportsProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [gridParams, setGridParams] = useState({
    page: 1,
    currencyDate: moment().format('YYYY-MM-DD'),
    currency: 'EUR',
    limit: 10,
  });
  const [reports, setReports] = useState([]);
  const [chartReports, setChartReports] = useState(null);

  const getReports = async query => {
    setLoading(true);
    return await api
      .get('/api/offers-reports', {
        params: query,
      })
      .then(response => {
        setReports(response.data);
        setLoading(false);
        return response.data;
      })
      .catch(error => {
        setLoading(false);
        return { error };
      });
  };

  const getChartReports = async query => {
    setLoading(true);
    return await api
      .get('/api/report', {
        params: query,
      })
      .then(response => {
        setChartReports(response.data);
        setLoading(false);
        return { data: response.data };
      })
      .catch(error => {
        setLoading(false);
        return { error };
      });
  };

  const materialReports = async data => {
    setLoading(true);
    return await api
      .post('/api/material-report/details', data)
      .then(response => {
        setChartReports(response.data);
        setLoading(false);
        return response.data;
      })
      .catch(error => {
        setLoading(false);
        return { error };
      });
  };

  const getExcelReportFile = async query => {
    const withQuery = require('with-query').default;
    return await fetch(withQuery(`${API_URL}/api/pdf/offers?`, { ...query, page: undefined }), {
      method: 'GET',
      headers: {
        Authorization: `Bearer ` + Cookies.get('access_token'),
      },
    })
      .then(res => res?.blob())
      .then(blob => {
        const fileReaderInstance = new FileReader();
        fileReaderInstance.readAsDataURL(blob);
        fileReaderInstance.onload = async () => {
          let base64 = fileReaderInstance.result;
          const link = document.createElement('a');

          link.href = base64;
          link.setAttribute('download', 'Reports.pdf');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        };
      })
      .catch(error => alert(error.message));
  };

  return (
    <ReportsContext.Provider
      value={{
        reports,
        chartReports,
        getReports,
        getChartReports,
        getExcelReportFile,
        materialReports,
        gridData: {
          getData: getReports,
          isLoading: loading,
          data: reports?.data || [],
          links: reports?.links || [],
          params: gridParams,
          setParams: setGridParams,
        },
      }}>
      {children}
    </ReportsContext.Provider>
  );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react';
import clsx from 'clsx';
import Select from 'react-select';

const mappedLabel = (label: string): string => {
  if (label === '&laquo; Previous') {
    return 'Previous';
  }

  if (label === 'Next &raquo;') {
    return 'Next';
  }

  return label;
};

type Props = {
  isLoading?: boolean;
  links?: [any];
  limit?: number;
  setActivePage: (page: any) => void;
  setLimit?: (perPage: any) => void;
};

const Pagination: FC<Props> = ({ isLoading, links, setActivePage, limit = 10, setLimit }) => {
  useEffect(() => {
    setLimit && !limit && setLimit(10);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const optionsPerPage = [
    { label: 10, value: 10 },
    { label: 25, value: 25 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
  ];

  return (
    <div className='row test'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
        {setLimit && (
          <Select
            onChange={value => setLimit(Number(value?.value))}
            options={optionsPerPage}
            placeholder='Per page'
            name='selectedCurrency'
            value={optionsPerPage.find(q => q.value === limit)}
            className='form-control-async-select'
            data-control='currency'
            data-hide-search='true'
          />
        )}
      </div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            {links
              ?.map(link => ({
                ...link,
                page: link.url ? link.url.split('page=')[1] : null,
              }))
              ?.map(link => {
                return { ...link, label: mappedLabel(link.label) };
              })
              .map(link => (
                <li
                  key={link.label}
                  className={clsx('page-item', {
                    active: link.active,
                    disabled: isLoading,
                    previous: link.label === 'Previous',
                    next: link.label === 'Next',
                  })}>
                  <a
                    className={clsx('page-link', {
                      'page-text': link.label === 'Previous' || link.label === 'Next',
                      'me-5': link.label === 'Previous',
                    })}
                    onClick={() => link.page && setActivePage(link.page)}
                    style={{ cursor: 'pointer' }}>
                    {mappedLabel(link.label)}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export { Pagination };

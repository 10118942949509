import { FC } from 'react';
import moment from 'moment';
import Chart from 'react-apexcharts';

type Props = {
  data: any;
};

const ReportChart: FC<Props> = ({ data }) => {
  const { report, currency } = data;

  // const newMaxDate = moment(graph.maxDate).add(4, 'days');
  // const newMinDate = moment(graph.minDate).subtract(3, 'days');

  // const xAxis = () => {
  //   let currentDate = newMinDate;
  //   let allDate = [];
  //   while (currentDate < newMaxDate) {
  //     allDate.push(moment(currentDate).format('MMM D'));
  //     currentDate = moment(currentDate).add(1, 'days');
  //   }
  //   return allDate;
  // };

  // const yAxis = () => {
  //   let currentPrice = Math.round(parseInt(graph.minPrice) / 10) * 10;
  //   let allPrices = [];
  //   while (currentPrice <= Math.ceil(parseInt(graph.maxPrice) / 10) * 10) {
  //     allPrices.push(currentPrice);
  //     currentPrice += 5;
  //   }
  //   return allPrices;
  // };

  // const setData = () => {};

  const options: any = {
    chart: {
      height: 350,
      zoom: {
        enabled: false,
      },
      id: 'basic-line',
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: 'Draft Grafic Pentru Materials Filtrate',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', '#f3f3f3'],
        opacity: 0.5,
      },
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      labels: {
        formatter: function (val: number) {
          return `${val.toFixed(0)} ${currency}`;
        },
      },
      title: {
        text: `Price(${currency})`,
      },
    },
  };

  const reportsData = report.map((q: any) =>
    q.material?.map((k: any) => ({
      type: 'line',
      name: q.partner.partner + ' ' + k.name,
      data: k.history?.map((j: any) => ({
        x: moment(j.date),
        y: parseFloat(j.priceConverted),
        id: k.id,
      })),
    }))
  );

  let series: any = [];
  reportsData.forEach((item: any, i: any) => series.push(...item));

  return (
    <div>
      <Chart options={options} series={series} height='750' type='line' />
    </div>
  );
};

export { ReportChart };

import React, { useState } from 'react';
import { api } from '../api';

export const CategoryMaterialsContext = React.createContext();

export const CategoryMaterialsProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [gridParams, setGridParams] = useState({
    page: 1,
    parent_id: 0,
    level: 1,
    limit: 10,
  });
  const [categoryMaterials, setCategoryMaterials] = useState({
    data: [],
  });

  const getCategoryMaterials = async query => {
    setLoading(true);
    return await api
      .get('/api/material-category', {
        params: query,
      })
      .then(response => {
        setCategoryMaterials(response.data);
        setLoading(false);
        return { data: response.data };
      })
      .catch(error => {
        setLoading(false);
        return { error };
      });
  };

  const getCategoryMaterialsForFilters = async query => {
    return await api.get('/api/material-category', {
      params: { ...query, limit: 1000 },
    });
  };

  const getCategoryMaterialDetalisForFilters = async id => {
    return await api.get(`/api/material-category/${id}`, {});
  };

  const getMultipleCategoryMaterialDetalisForFilters = async id => {
    return await api.get(`/api/material-category/multiple?ids=${id}`, {});
  };

  const getCategoryMaterial = async id => {
    return await api
      .get(`/api/material-category/${id}`)
      .then(response => {
        return {
          data: response.data,
        };
      })
      .catch(error => ({ error }));
  };

  const addCategoryMaterial = async data => {
    return await api
      .post('/api/material-category', data)
      .then(response => ({ data: response.data }))
      .catch(error => ({ error }));
  };

  const editCategoryMaterial = async (id, data) => {
    return await api
      .put(`/api/material-category/${id}`, data)
      .then(response => {
        return { data: response.data };
      })
      .catch(error => {
        return { error };
      });
  };

  return (
    <CategoryMaterialsContext.Provider
      value={{
        categoryMaterials,
        getCategoryMaterials,
        getCategoryMaterial,
        addCategoryMaterial,
        editCategoryMaterial,
        getCategoryMaterialsForFilters,
        getCategoryMaterialDetalisForFilters,
        getMultipleCategoryMaterialDetalisForFilters,
        gridData: {
          getData: getCategoryMaterials,
          isLoading: loading,
          data: categoryMaterials?.data || [],
          links: categoryMaterials?.links || [],
          params: gridParams,
          setParams: setGridParams,
        },
      }}>
      {children}
    </CategoryMaterialsContext.Provider>
  );
};

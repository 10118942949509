import { FC, useEffect, useState } from 'react';
import { MenuComponent } from '../../../_metronic/assets/ts/components';
import { KTSVG } from '../../../_metronic/helpers';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

type Props = {
  onFilterCurrency: any;
  params?: any;
};

const CurrencyFilter: FC<Props> = ({ onFilterCurrency, params }) => {
  const [filterParams, setFilterParams] = useState({
    date: params?.date || null,
    dateFrom: params?.dateFrom ? new Date(params?.dateFrom) : null,
    dateTo: params?.dateTo ? new Date(params?.dateTo) : null,
  });

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const resetData = () => {
    const initialParams = {
      date: null,
      dateFrom: null,
      dateTo: null,
    };
    setFilterParams(initialParams);
    onFilterCurrency(initialParams);
  };

  const filterData = () => {
    onFilterCurrency(filterParams);
  };

  const onChange = (dates: any) => {
    const [start, end] = dates;
    setFilterParams({ ...filterParams, dateFrom: start, dateTo: end });
  };

  return (
    <>
      <button
        type='button'
        className='btn btn-clean font-weight-bold'
        data-kt-menu-trigger='click'
        style={{ padding: 0, margin: 0 }}
        data-kt-menu-placement='bottom-end'>
        <KTSVG path='/media/icons/duotune/general/gen031.svg' />
      </button>
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-700px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filtre</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5 d-flex flex-wrap' data-kt-user-table-filter='form'>
          <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>Data from</label>
            <DatePicker
              selected={filterParams.dateFrom}
              startDate={filterParams.dateFrom}
              endDate={filterParams.dateTo}
              onChange={onChange}
              placeholderText='Data'
              dateFormat='dd/MM/yyyy'
              className='form-control form-control-solid form-select-sm form-select mb-3 mb-lg-0'
              selectsRange
            />
          </div>

          <div className='d-flex align-items-center '>
            <button
              type='button'
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'>
              Reseteaza
            </button>
            <button
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'>
              Aplica
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export { CurrencyFilter };

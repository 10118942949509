/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import { ShopsTable } from '../../modules/shops/ShopsTable';

const ShopsWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.SHOPS' })}</PageTitle>
      <div className=''>
        <div className=''>
          <ShopsTable />
        </div>
      </div>
    </>
  );
};

export { ShopsWrapper };

import { FC, useState } from 'react';
import * as Yup from 'yup';
import moment from 'moment';

import { KTSVG } from '../../../_metronic/helpers';

import { useFormik } from 'formik';
import { useShopsContext } from '../../../context/context';

import AsyncSelect from 'react-select/async';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import clsx from 'clsx';

type Props = {
  openModal: any;
  onClose: any;
  shop: any;
};

const editShopsSchema = Yup.object().shape({
  // name: Yup.string().required('Pseudonim este obligatoriu!'),
  // cost_center: Yup.string().required('Centrul de Cost este obligatoriu!'),
  // type: Yup.string().required('Tipul este obligatoriu!'),
  // area: Yup.number().required('Suprafata salii de vanzare este obligatorie!'),
  // // open_date: Yup.string().required('Anul/Luna de Deschidere este obligatorie!'),
  // // city: Yup.number().required('Orasul este obligatoriu!'),
  // address: Yup.string().required('Strada/Nr este obligatorie!'),
  // postal_code: Yup.string().required('Codul Postal este obligatoriu!'),
});

const ShopsAddEditModal: FC<Props> = ({ openModal, onClose, shop }) => {
  const isEdit = Object.values(shop).length > 0;

  const { addShop, editShop, cityRegionList } = useShopsContext();

  const [selectedCity, setSelectedCity] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [formErrors, setFormErrors] = useState<any>({});

  const [editData] = useState({
    ...shop,
    cost_center: shop.cost_center || '',
    name: shop.name || '',
    type: shop.type || '',
    area: shop.area || '',
    open_date: shop.open_date || null,
    city_id: shop.city_id || null,
    address: shop.address || '',
    postal_code: shop.postal_code || '',
    director_email: shop.director_email || '',
    constructions_email: shop.constructions_email || '',
    id: shop ? shop.id : null,
  });

  const formik = useFormik({
    initialValues: editData,
    validationSchema: editShopsSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setFormErrors({});

      const data = {
        cost_center: values.cost_center,
        name: values.name,
        type: values.type,
        area: values.area,
        open_date: selectedDate ? moment(selectedDate).format('YYYY/MM') : values.open_date,
        city_id: selectedCity ? selectedCity : values.city_id,
        address: values.address,
        postal_code: values.postal_code,
        director_email: values.director_email,
        constructions_email: values.constructions_email,
      };

      let res: any = null;
      if (isEdit) {
        res = await editShop(shop.id, data);
      } else {
        res = await addShop(data);
      }

      setSubmitting(false);

      if (res.error) {
        if (res.error.response.data) {
          let errors = {};
          Object.keys(res.error.response.data).forEach(name => {
            errors = {
              ...errors,
              [name]: res.error.response.data[name][0],
            };
          });
          formik.setErrors(errors);
          setFormErrors(errors);
        }
      } else {
        onClose && onClose();
      }
    },
  });

  const onHandleSearchCity = (inputValue: string) =>
    new Promise<any>(async resolve => {
      const cities = await cityRegionList({ q: inputValue });

      resolve(
        cities?.data?.map((q: any) => ({
          label: (q.region?.name ?? '') + ' Jud.' + q.county + ' - ' + q.name,
          value: q.id,
        })) || []
      );
    });

  const onHandleSelectCity = (city: any) => {
    setSelectedCity(city.value);
  };

  const onChangeDate = (value: any) => {
    setSelectedDate(value);
  };

  const defaultCity = isEdit
    ? [editData.city]?.map((q: any) => ({
        label: (q.region?.name ?? '') + ' Jud.' + q.county + ' - ' + q.name,
        value: q.id,
      }))
    : undefined;

  return (
    <>
      <div className='modal fade show d-block' id='kt_modal_add_user' role='dialog' tabIndex={-1} aria-modal='true'>
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>{isEdit ? 'Editare magazin' : 'Adaugare magazin'}</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => onClose()}
                style={{ cursor: 'pointer' }}>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <form onSubmit={formik.handleSubmit} id='kt_modal_add_user_form' className='form' autoComplete={'off'}>
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Centru de cost</label>
                  <input
                    placeholder='Centru de cost'
                    {...formik.getFieldProps('cost_center')}
                    type='text'
                    name='cost_center'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.cost_center && formik.errors.cost_center },
                      {
                        'is-valid': formik.touched.cost_center && !formik.errors.cost_center,
                      }
                    )}
                    autoComplete='off'
                    value={formik.values.cost_center}
                    disabled={formik.isSubmitting}
                  />
                  {formErrors.cost_center && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formErrors.cost_center}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Pseudonym</label>
                  <input
                    placeholder='Pseudonym'
                    {...formik.getFieldProps('name')}
                    type='text'
                    name='name'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.name && formik.errors.name },
                      {
                        'is-valid': formik.touched.name && !formik.errors.name,
                      }
                    )}
                    autoComplete='off'
                    value={formik.values.name}
                    disabled={formik.isSubmitting}
                  />
                  {formErrors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formErrors.name}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Tipul magazinului</label>
                  <input
                    placeholder='Tipul magazinului'
                    {...formik.getFieldProps('type')}
                    type='text'
                    name='type'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.type && formik.errors.type },
                      {
                        'is-valid': formik.touched.type && !formik.errors.type,
                      }
                    )}
                    autoComplete='off'
                    value={formik.values.type}
                    disabled={formik.isSubmitting}
                  />
                  {formErrors.type && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formErrors.type}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Suprafata</label>
                  <input
                    placeholder='Suprafata'
                    {...formik.getFieldProps('area')}
                    type='number'
                    name='area'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.area && formik.errors.area },
                      {
                        'is-valid': formik.touched.area && !formik.errors.area,
                      }
                    )}
                    autoComplete='off'
                    value={formik.values.area}
                    disabled={formik.isSubmitting}
                  />
                  {formErrors.area && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formErrors.area}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>An/Luna</label>
                  <DatePicker
                    // {...formik.getFieldProps('open_date')}
                    selected={selectedDate}
                    onChange={onChangeDate}
                    value={selectedDate ? selectedDate : editData.open_date}
                    placeholderText='An/Luna'
                    dateFormat='yyyy/MM'
                    showMonthYearPicker
                    className='form-control form-control-solid form-select-sm form-select mb-3 mb-lg-0'
                  />

                  {formErrors.open_date && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formErrors.open_date}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Oras</label>
                  <AsyncSelect
                    // {...formik.getFieldProps('city_id')}
                    loadOptions={onHandleSearchCity}
                    onChange={onHandleSelectCity}
                    defaultValue={defaultCity?.length ? defaultCity[0] : null}
                    placeholder='Oras'
                    name='city_id'
                    defaultOptions={defaultCity}
                    // className='form-control form-control-solid form-select-sm form-select '
                  />

                  {formErrors.city_id && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formErrors.city_id}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Strada/Nr</label>
                  <input
                    placeholder='Strada/Nr'
                    {...formik.getFieldProps('address')}
                    type='text'
                    name='address'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.address && formik.errors.address },
                      {
                        'is-valid': formik.touched.address && !formik.errors.address,
                      }
                    )}
                    autoComplete='off'
                    value={formik.values.address}
                    disabled={formik.isSubmitting}
                  />
                  {formErrors.address && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formErrors.address}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>ZIP</label>
                  <input
                    placeholder='ZIP'
                    {...formik.getFieldProps('postal_code')}
                    type='text'
                    name='postal_code'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.postal_code && formik.errors.postal_code },
                      {
                        'is-valid': formik.touched.postal_code && !formik.errors.postal_code,
                      }
                    )}
                    autoComplete='off'
                    value={formik.values.postal_code}
                    disabled={formik.isSubmitting}
                  />
                  {formErrors.postal_code && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formErrors.postal_code}</span>
                      </div>
                    </div>
                  )}
                </div>
                {isEdit && (
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Email BackOffice Constructions</label>
                    <input
                      placeholder='Email BackOffice Constructions'
                      type='text'
                      name='constructions_email'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      value={formik.values.constructions_email}
                      disabled
                    />
                  </div>
                )}
                {isEdit && (
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Email Manager Construction Department</label>
                    <input
                      placeholder='Email Manager Construction Department'
                      type='text'
                      name='postal_code'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      value={formik.values.director_email}
                      disabled
                    />
                  </div>
                )}
                <button type='submit' className='btn btn-sm btn-primary' disabled={formik.isSubmitting}>
                  Salveaza
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  );
};

export { ShopsAddEditModal };

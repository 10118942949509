import * as axios from 'axios';
import Cookies from 'js-cookie';

const API_URL = process.env.REACT_APP_API_URL;

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: 'Bearer ' + Cookies.get('access_token'),
  },
});

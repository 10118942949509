/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl';
import { AsideMenuItem } from './AsideMenuItem';
import { useAuthContext } from '../../../../context/context';
import { ROLE_KABA } from '../../../../constants';

export function AsideMenuMain() {
  const intl = useIntl();
  const { authUser } = useAuthContext();

  return (
    <>
      <AsideMenuItem
        to='/users'
        icon='/media/icons/duotune/general/gen022.svg'
        title={intl.formatMessage({ id: 'MENU.USERS' })}
        fontIcon='bi-app-indicator'
      />
      {authUser.role === ROLE_KABA && (
        <>
          <AsideMenuItem
            to='/currency'
            icon='/media/icons/duotune/general/gen003.svg'
            title={intl.formatMessage({ id: 'MENU.CURRENCY' })}
            fontIcon='bi-app-indicator'
          />

          <AsideMenuItem
            to='/shops'
            icon='/media/icons/duotune/general/gen009.svg'
            title={intl.formatMessage({ id: 'MENU.SHOPS' })}
            fontIcon='bi-app-indicator'
          />

          <AsideMenuItem
            to='/partners'
            icon='/media/icons/duotune/general/gen016.svg'
            title={intl.formatMessage({ id: 'MENU.PARTNERS' })}
            fontIcon='bi-app-indicator'
          />

          <AsideMenuItem
            to='/materials'
            icon='/media/icons/duotune/general/gen017.svg'
            title={intl.formatMessage({ id: 'MENU.MATERIALS' })}
            fontIcon='bi-app-indicator'
          />

          <AsideMenuItem
            to='/sub-families'
            icon='/media/icons/duotune/general/gen017.svg'
            title={intl.formatMessage({ id: 'MENU.SUB_FAMILIES' })}
            fontIcon='bi-app-indicator'
          />

          <AsideMenuItem
            to='/kaufland-codes'
            icon='/media/icons/duotune/general/gen017.svg'
            title={intl.formatMessage({ id: 'MENU.KAUFLAND_CODES' })}
            fontIcon='bi-app-indicator'
          />

          <AsideMenuItem
            to='/sub-articles'
            icon='/media/icons/duotune/general/gen017.svg'
            title={intl.formatMessage({ id: 'MENU.SUB_ARTICLES' })}
            fontIcon='bi-app-indicator'
          />

          <AsideMenuItem
            to='/deals'
            icon='/media/icons/duotune/general/gen025.svg'
            title={intl.formatMessage({ id: 'MENU.OFERTE' })}
            fontIcon='bi-app-indicator'
          />

          <AsideMenuItem
            to='/reports'
            icon='/media/icons/duotune/art/art002.svg'
            title={intl.formatMessage({ id: 'MENU.REPORTS' })}
            fontIcon='bi-app-indicator'
          />
        </>
      )}

      {/* 
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div> */}

      {/*       
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'>
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/connections' title='Connections' hasBullet={true} />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/wizards/horizontal' title='Horizontal' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'>
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'>
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'>
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'>
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a target='_blank' className='menu-link' href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}>
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  );
}

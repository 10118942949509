import { FC } from 'react';
import { UserModalHeader } from './UserModalHeader';
import { UserModalContent } from './UserModalContent';

type Props = {
  openModal: any;
  onClose: any;
  data: any;
};

const UserAddEditModal: FC<Props> = ({ openModal, onClose, data }) => {
  const isEdit = Object.values(data).length > 0;
  return (
    <>
      {openModal && (
        <>
          <div className='modal fade show d-block' id='kt_modal_add_user' role='dialog' tabIndex={-1} aria-modal='true'>
            <div className='modal-dialog modal-dialog-centered mw-650px'>
              <div className='modal-content'>
                <UserModalHeader onClose={onClose} isEdit={isEdit} />
                <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                  <UserModalContent user={data} isEdit={isEdit} onClose={onClose} />
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
    </>
  );
};

export { UserAddEditModal };

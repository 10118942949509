import { AuthProvider } from './providers/AuthProvider';
import { UsersProvider } from './providers/UsersProvider';
import { ReportsProvider } from './providers/ReportsProvider';
import { CurrencyProvider } from './providers/CurrencyProvider';
import { PartnersProvider } from './providers/PartnersProvider';
import { CategoryMaterialsProvider } from './providers/CategoryMaterialsProvider';
import { MaterialsProvider } from './providers/MaterialsProvider';
import { ShopsProvider } from './providers/ShopsProvider';
import { OffersProvider } from './providers/OffersProvider';
import { MeasurementUnitsProvider } from './providers/MeasurementUnitsProvider';

export const Provider = ({ children }) => (
  <AuthProvider>
    <MeasurementUnitsProvider>
      <OffersProvider>
        <ShopsProvider>
          <ReportsProvider>
            <CurrencyProvider>
              <UsersProvider>
                <PartnersProvider>
                  <CategoryMaterialsProvider>
                    <MaterialsProvider>{children}</MaterialsProvider>
                  </CategoryMaterialsProvider>
                </PartnersProvider>
              </UsersProvider>
            </CurrencyProvider>
          </ReportsProvider>
        </ShopsProvider>
      </OffersProvider>
    </MeasurementUnitsProvider>
  </AuthProvider>
);

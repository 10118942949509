/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { PageTitle } from '../../../_metronic/layout/core';
import { useReportsContext } from '../../../context/context';
import { ReportChart } from '../../modules/reportChart/ReportChart';

const ReportChartWrapper: FC = () => {
  const { chartReports, materialReports } = useReportsContext();

  const intl = useIntl();
  const { data } = useParams();
  useEffect(() => {
    materialReports(data ? JSON.parse(data) : {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.REPORTS_CHART' })}</PageTitle>
      {chartReports && <ReportChart data={chartReports} />}
    </>
  );
};

export { ReportChartWrapper };
